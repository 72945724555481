import _u from "wtc-utility-helpers";
import Modal from "wtc-modal-view";
import CONFIG from "../config";

let helpers = {};

helpers.initVideos = function() {
  let hash = Modal.hash;
  let openedEl = null;
  const lastItem = document.createElement("div");
  lastItem.setAttribute("tabindex", "0");

  lastItem.addEventListener(
    "focus",
    function() {
      Modal.instance.modalClose.focus();
    },
    false
  );

  const openVideo = function(videoId, hash, element) {
    openedEl = element;

    Modal.openVideo({
      videoId: videoId,
      hash: hash,
      chromeless: false
    });

    setTimeout(() => {
      Modal.instance.modalClose.focus();
      Modal.modalContent.appendChild(lastItem);
    }, 100);

    Modal.onClose = function() {
      openedEl.focus();
    };
  };

  if (hash) {
    let el = document.querySelector(`[data-video-hash="${hash}"]`);

    if (el) {
      let id = el.getAttribute("data-video");

      if (id) {
        setTimeout(function() {
          openVideo(id, hash, el);
        }, 250);
      }
    }
  }

  try {
    nclood.mount("nclood-video");
  } catch (e) {}

  _u.forEachNode(
    document.querySelectorAll("[data-video]"),
    (index, element) => {
      let id = element.getAttribute("data-video");

      element.removeAttribute("data-video");
      element.addEventListener("click", e => {
        let hash = element.getAttribute("data-video-hash");

        openVideo(id, hash, element);
      });
    }
  );
};

helpers.getTrackingOpts = function(body) {
  // Set up all our tracking
  let base = body ? body : document.getElementById("wrap");
  let pagename = base.getAttribute("data-page-omniture") || null;
  let ishome = base.getAttribute("data-page") == "home" ? true : false;
  let lang = CONFIG.CONST.LANGUAGE;
  let referrer = /(\?|\&)r=([a-zA-Z0-9\.]+)/.exec(window.location.href);

  if (referrer && referrer.length === 3) {
    referrer = referrer[2];
  } else {
    referrer = null;
  }

  return {
    trackerProps: {
      suite: CONFIG.CONST.SUITE[lang.toUpperCase()],
      siteName: CONFIG.CONST.SITENAME,
      siteType: CONFIG.CONST.SITETYPE,
      pageName: pagename,
      platform: CONFIG.CONST.PLATFORM
    },
    pageTrackOpts: {
      vanity: referrer,
      productCode: CONFIG.CONST.PRODUCTCODE,
      productName: CONFIG.CONST.PRODUCTNAME,
      productId: CONFIG.CONST.PRODUCTID,
      gameTitle: CONFIG.CONST.PRODUCTNAME,
      genre: CONFIG.CONST.GENRE,
      publisher: CONFIG.CONST.PUBLISHER,
      isHome: ishome
    }
  };
};

export default helpers;
