import Record from './Record';

let types = ['event', 'game', 'placeholder', 'split'];
let _required = ['type'];

class EventModel extends Record {

  constructor(data) {
    super(data, _required);
  }

  get type() {
    return this._type;
  }
  set type(type) {
    if(typeof type != 'string') {
      throw new Error('Type must be of type String');
    }
    if(types.indexOf(type) < 0 ) {
      throw new Error('Type (' + type + ') must be one of: ' + types.join(', '))
    }
    this._type = type;
  }
}

export default EventModel;
