import DisplayObject from './DisplayObject';
import Event from './Event';
import Era from './Era';
import Label from './Label';
import Vector from "../../../../../node_modules/wtc-vector/dist/es5-bundle"
import { TweenMax, Power2 } from "gsap";

const margin = 30;

/**
 * Timeline is the view layer for timelines. It is responsibele for controlling the layout and display of the timeline.
 *
 * This class also allows for the nesting of other timelines
 *
 * @class DisplayObject.Timeline
 * @author Liam Egan <liam@wethecollective.com>
 * @version 0.5
 * @created March 16, 2017
 */
class Timeline extends DisplayObject {
  constructor(data) {
    super();

    this.data = data;

    this.tracking_centre = margin;

    // draw a rectangle
    this.graphics = new PIXI.Graphics();
    this.graphics.position.y = -3;
    // this.graphics.position.x = tracking_centre;
    this.addChild(this.graphics);

    if(this.data.title) this.addLabel(this.data.title);
  }

  drawTimeline() {
    this.graphics.clear();
    this.graphics.beginFill(0xB99F65);
    this.graphics.drawRect(0, 0, this.tracking_centre, 6);
  }

  addLabel(text) {
    let labelDisplay = new Label(text);

    this.tracking_centre += labelDisplay.width / 2;

    this.addChild(labelDisplay);

    labelDisplay.position.x = this.tracking_centre - labelDisplay.width;
    this.labelOffset = labelDisplay.position.x;
    labelDisplay.position.y = -(labelDisplay.height / 2);

    this.tracking_centre += margin;

    this.drawTimeline();
  }

  addEvent(eventData) {
    let eventDisplay = new Event(eventData);

    this.tracking_centre += eventDisplay.width / 2;

    this.eventsContainer.addChild(eventDisplay);

    eventDisplay.position.x = this.tracking_centre;

    if( eventData.relation ) {
      this.addEra(eventData.relation);
    }

    if( !eventData.lastEvent ) {
      this.tracking_centre += eventDisplay.width / 2 + margin;
    }


    this.drawTimeline();
  }
  addEra(eraData) {
    let eraDisplay = new Era(eraData);

    this.erasContainer.addChild(eraDisplay);

    eraDisplay.position.x = this.tracking_centre;

    this.drawTimeline();
  }

  /**
   * Getters and setters
   */


  set labelOffset(value) {
    if(typeof value == 'number') this._labelOffset = value;
  }
  get labelOffset() {
    return this._labelOffset || 0;
  }

  /**
   * (getter/setter) secondaryTimeline - A property representing a secondary timeline. Nested so we can appropriately control dependency of multiple timelines. This approach is suprisingly elegant.
   *
   * @type {DisplayObject.Timeline}
   */
  set secondaryTimeline(timeline) {
    if(timeline instanceof Timeline) {
      if(this._secondaryTimeline) {
        this.removeChild(this._secondaryTimeline);
      }
      this._secondaryTimeline = timeline;
      // This is here because for some reason this calculation was causing issues.
      // Probably a race condition between the secondary timeline being added and
      // the width of this timeline being updated.
      this._secondaryTimeline.alpha = 0;
      window.secondary = this._secondaryTimeline;
      let originalWidth = this.width; // this because adding the child *obviously* changes the width of *this*
      setTimeout(()=> {
        this._secondaryTimeline.position.x = originalWidth + this.labelOffset;
        TweenMax.to(this._secondaryTimeline, 0.4, { alpha: 1 });
      }, 100);
      this.addChild(this._secondaryTimeline);
    }
  }
  get secondaryTimeline() {
    return this._secondaryTimeline;
  }

  get eventNearMiddle() {
    let centre = new Vector(window.innerWidth / 2, window.innerHeight / 2);
    let returnEvent, lastDistance = new Vector(1000, 1000);
    this.eventsContainer.children.forEach((_event) => {
      let absCentre = _event.absCentre;
      absCentre = new Vector(absCentre.x, absCentre.y);
      let distance = absCentre.subtract(centre);

      if(distance.length < lastDistance.length) {
        lastDistance = distance;
        returnEvent = _event;
      }
    });
    return { distance: lastDistance.length, event: returnEvent }
  }

  get eventsContainer() {
    if(!this._eventsContainer) {
      this.erasContainer;
      this._eventsContainer = new PIXI.Container;
      this.addChild(this._eventsContainer);
    }
    return this._eventsContainer;
  }
  get erasContainer() {
    if(!this._erasContainer) {
      this._erasContainer = new PIXI.Container;
      this.eventsContainer;
      this.addChild(this._erasContainer);
    }
    return this._erasContainer;
  }
}

export default Timeline;
