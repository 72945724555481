import _u from "wtc-utility-helpers";
import {
  default as ElementController,
  ExecuteControllers,
} from "wtc-controller-element";

class Nav extends ElementController {
  constructor(element, options = {}) {
    super(element);
    this.nComBar = document.querySelector(".nclood-nav-wrapper");

    this.sticky();
  }

  // Make nav sticky when scrolling
  sticky() {
    let minH = this.nComBar.offsetHeight,
      yPos = window.pageYOffset;

    if (yPos > minH) {
      _u.addClass("nav-sticky", document.body);
    } else {
      _u.removeClass("nav-sticky", document.body);
    }

    window.requestAnimationFrame(this.sticky.bind(this));
  }
}

ExecuteControllers.registerController(Nav, "Nav");

export default Nav;
