import DisplayObject from './DisplayObject';

const marginLeft = 10;
const marginTop = 20;

class Era extends DisplayObject {
  constructor(data) {
    super(data);

    this.data = data;
    this.data.displayObject = this;

    this.init();
  }
  init() {
    let container, style, text, graphics;

    // Renderables
    container = new PIXI.Container(); // container for everything
    style = this.styleEra;
    text = new PIXI.Text(this.data.title, style);
    text.position.x = marginLeft;
    text.position.y = marginTop;
    container.addChild(text);
    // draw a rectangle
    graphics = new PIXI.Graphics();
    graphics.beginFill(0xB99F65);
    graphics.drawRect(0, 0, 3, 34);
    container.addChild(graphics);
    container.x = -1.5;
    this.addChild(container);
  }
}

export default Era;
