import Record from './Record'
import EventModel from './Event';
import EraModel from './Era';
import TimelineDisplay from '../DisplayObject/Timeline'

let instance = null;

class Timeline extends Record {

  constructor(data) {
    super(data);

    this.events = [];
    this._eventKeys = {};
    this.eras = [];
    this._eraKeys = {}
    this.timelines = [];
  }

  addEvent(event, timeline, position) {
    if( event instanceof EventModel ) {
      event.timeline = this;
      if( !isNaN(position) ) {
        this.events.splice(position, 0, event);
      } else {
        this.events.push(event);
      }
    }

    this._generateKeys('events');
  }

  addEra(era, timeline, position) {
    if( era instanceof EraModel ) {
      if( !isNaN(position) ) {
        this.eras.splice(position, 0, era);
      } else {
        this.eras.push(era);
      }
    }

    this._generateKeys('eras');
  }

  findEventNearMiddle() {
    if( this.displayObject ) return this.displayObject.eventNearMiddle;
  }

  getEventById(id) {
    if( typeof id != 'string' ) {
      throw new Error('ID should be of type String');
    } else if( this._eventKeys[id] == undefined ) {
      return null;
    }

    return this._eventKeys[id];
  }
  getEraById(id) {
    if( typeof id != 'string' ) {
      throw new Error('ID should be of type String');
    } else if( this._eraKeys[id] == undefined ) {
      return null;
    }

    return this._eraKeys[id];
  }

  getEventByIndex(index) {
    return this.events[index];
  }
  getEraByIndex(index) {
    return this.events[index];
  }

  _generateKeys(forWhat) {
    if(forWhat == 'events') {
      this._eventKeys = {};
      this.events.forEach((eventInstance, i) => {
        eventInstance.index = i;
        eventInstance.firstEvent = false;
        eventInstance.lastEvent = false;
        if( i === 0 ) {
          eventInstance.firstEvent = true;
        }
        if( i === this.events.length-1 ) {
          eventInstance.lastEvent = true;
        }
        this._eventKeys[eventInstance.id] = eventInstance;
      });
    }
    if(forWhat == 'eras') {
      this._eraKeys = {};
      this.eras.forEach((eraInstance, i) => {
        eraInstance.index = i;
        this._eraKeys[eraInstance.id] = eraInstance;
      });
    }
  }


  /**
   * Getters and setters
   */

  /**
   * (getter/setter) secondaryTimeline - A property representing a secondary timeline. This gets added to the timeline instance associated with this model.
   *
   * @type {DisplayObject.Timeline}
   * @default undefined
   */
  set secondaryTimeline(timeline) {
    if(timeline instanceof TimelineDisplay && this.displayObject instanceof TimelineDisplay) {
      this.displayObject.secondaryTimeline = timeline;
    }
  }
  get secondaryTimeline() {
    if(this.displayObject instanceof TimelineDisplay) return this.displayObject.secondaryTimeline;
  }
}

export default Timeline;
