import _u from 'wtc-utility-helpers';
import {default as ElementController, ExecuteControllers}  from 'wtc-controller-element';
import Modal from 'wtc-modal-view';

class Tears extends ElementController {
  constructor (element) {
    super(element);
    setTimeout(function() {
      let content = document.querySelectorAll('.content');
      content.forEach(item => item.classList.add('fade'))
    }, 1000);
  }
}

ExecuteControllers.registerController(Tears, 'Tears');

export default Tears;
