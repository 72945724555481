import Record from './Record';

let _required = [];

class EraModel extends Record {
  constructor(data) {
    super(data, _required);
  }
}

export default EraModel;
