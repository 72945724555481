import DisplayObject from './DisplayObject';
import { TweenMax, Power2 } from "gsap";

const bottomPosition = -20;
const marginText = 5;
const scaleSize = 1.8;

const createCircle = function(radius, fill = 0x000000, stroke = 0xB99F65, strokeWidth = 3) {

  let shape = new PIXI.Graphics();
  shape.lineStyle(strokeWidth * 2, stroke);
  shape.beginFill(fill);
  shape.drawCircle(0, 0, radius * 2);

  let texture = shape.generateTexture();
  let shapeContainer = new PIXI.Container();
  let shapeSprite = new PIXI.Sprite(texture);
  shapeSprite.scale.x = 0.5;
  shapeSprite.scale.y = 0.5;
  shapeSprite.x = shapeSprite.y = -radius-1;
  shapeContainer.addChild(shapeSprite);
  return shapeContainer;
};

/**
 * EventSelected event. Triggered when an event is selected
 *
 * @event EventSelected
 * @memberof DisplayObject.Event
 * @type {object}
 * @property {Event} event - The event object being propagated
 */
/**
 * TimelineSelected event. Triggered when a timeline is selected
 *
 * @event TimelineSelected
 * @memberof DisplayObject.Event
 * @type {object}
 * @property {PIXI.Container} button - The button that was pressed
 * @property {String} timelineName - The name of the secondary timeline to extend
 */

/**
 * Event is a class that provides PIXI functionality to to a displayObject wrapper and is used for rendering events on the timeline.
 *
 * This class has different types, specifically:
 * - event - a generic event on the timeline. Normally describes something that took place outside of a game;
 * - game - a game record. Selecting one of these will spawn a custom event *EventSelected* that should be used to display game details;
 * - placeholder a non-visible event type that is simply used to anchor an Era display object; and
 * - split this is used to ask a question about a split in the timeline.
 *
 * @param  {object} data The data that represents the event, supplied by the model
 * @class Event
 * @memberof DisplayObject
 * @author Liam Egan <liam@wethecollective.com>
 * @version 0.5
 * @created March 16, 2017
 *
 */
class Event extends DisplayObject {

  constructor(data) {
    super();

    this.data = data;
    this.data.displayObject = this;

    this.init();
  }


  /**
   * Initialises the object.
   *
   * @public
   * @todo Add object removal here as well.
   */
  init() {
    if(this.data.type == 'event') {
      this.renderEvent();
    } else if(this.data.type == 'game') {
      this.renderGame();
    } else if(this.data.type == 'split') {
      this.renderSplit();
    }
  }

  renderEvent() {
    let style = this.styleEventText;
    let text = new PIXI.Text(this.data.text, style);
    text.position.x = 0 - text.width / 2;
    text.position.y = bottomPosition - text.height;
    this.addChild(text);
  }

  renderGame() {
    // hoisting
    let container, textContainer, title, radio, radioStroke, radioInside, positionY, text;

    // Interaction variables
    this.selected = false;

    // listeners
    window.addEventListener('EventSelected', this.onEventSelected.bind(this));
    window.addEventListener('EventUnselected', this.onEventUnselected.bind(this));
    window.addEventListener('TimelineDrag', this.onTimelineDrag.bind(this));
    window.addEventListener('TimelineFoundEvent', this.onTimelineFoundEvent.bind(this));

    // Misc
    let radioSize = 7;

    // Renderables
    container = new PIXI.Container(); // container for everything
    textContainer = new PIXI.Container(); // container for just the text
    title = new PIXI.Text(this.data.title, this.styleEventTitle); // Game title

    radio = new PIXI.Container(); // the container for tthe radio button
    this.radioStroke = createCircle(radioSize);
    this.radioFilled = createCircle(radioSize, 0xFFFFFF);

    this.radioFilled.alpha = 0;
    radio.addChild(this.radioStroke);
    radio.addChild(this.radioFilled);

    title.position.x = 0 - title.width / 2;
    positionY = title.height + marginText; // modifying the position in the /y
    textContainer.addChild(title);
    if( typeof this.data.text == 'object' && this.data.text.length ) {
      this.data.text.forEach((t) => {
        let text = new PIXI.Text(t, this.styleEventText);
        text.position.y = positionY;
        text.position.x = 0 - text.width / 2;
        positionY += text.height + marginText;
        textContainer.addChild(text);
      });
    } else if( typeof this.data.text == 'string') {
      text = new PIXI.Text(this.data.text, this.styleEventText);
      text.position.y = positionY;
      text.position.x = 0 - text.width / 2;
      textContainer.addChild(text);
    }
    textContainer.y = bottomPosition - textContainer.height;
    // container.position.y = bottomPosition - container.height;
    // radio.position.y = container.height - bottomPosition; // aligning the radio with the bottom of the container

    container.addChild(textContainer);
    container.addChild(radio);
    this.addChild(container);

    // Set up the button
    this.interactive = true;
    this.buttonMode = true;
    this.on('pointerdown', ()=> {
      this.clickIntention = true;
    });
    this.on('pointerup', ()=> {
      if(this.clickIntention) this.select(true);
      this.clickIntention = false;
    });

    let mouseOver = function() {
      TweenMax.to(this.radioStroke.scale, 0.2, { y: scaleSize, x:  scaleSize});
      TweenMax.to(this.radioFilled.scale, 0.2, { y: scaleSize, x:  scaleSize});
    };

    let mouseOut = function() {
      if(!this.selected ) {
        TweenMax.to(this.radioFilled.scale, 0.2, { y: 1, x:  1});
        TweenMax.to(this.radioStroke.scale, 0.2, { y: 1, x:  1});
      }
    };


    this.on('mouseover', mouseOver);
    this.on('mouseout', mouseOut);
  }

  renderSplit() {
    let container, radio, radioStroke, tmp, txtSize, buttonPadding = 10, createButton, buttonDistance = 80, line, option1Data, option2Data;
    this.option1 = null;
    this.option2 = null;
    this.leaderLineContainer = null;

    // Interaction variables
    this.selected = false;
    this.data.timelineName = undefined;
    this.data.timelineSelected = true;

    // listeners
    window.addEventListener('EventSelected', this.onEventSelected.bind(this));
    window.addEventListener('EventUnselected', this.onEventUnselected.bind(this));
    window.addEventListener('TimelineDrag', this.onTimelineDrag.bind(this));
    window.addEventListener('TimelineFoundEvent', this.onTimelineFoundEvent.bind(this));
    window.addEventListener('DirectionMove', this.onDirectionMove.bind(this));

    // Misc
    let radioSize = 10;

    // Renderables
    container = new PIXI.Container(); // container for everything
    this.leaderLineContainer = new PIXI.Container(); // the container for the leader line
    // the line in the background
    line = new PIXI.Graphics();
    line.beginFill(0xB99F65);
    line.drawRect(-3, -buttonDistance, 6, buttonDistance*2);
    // The radio button thing
    radio = new PIXI.Container();
    this.radioStroke = new PIXI.Graphics();
    this.radioStroke.lineStyle(3, 0xB99F65);
    this.radioStroke.beginFill(0x000000);
    this.radioStroke.moveTo(0, -radioSize);
    this.radioStroke.lineTo(radioSize, 0);
    this.radioStroke.lineTo(0, radioSize);
    this.radioStroke.lineTo(-radioSize, 0);
    this.radioStroke.lineTo(0, -radioSize);
    this.radioStroke.endFill();
    this.radioFilled = new PIXI.Graphics();
    this.radioFilled.lineStyle(3, 0xB99F65);
    this.radioFilled.beginFill(0xFFFFFF);
    this.radioFilled.moveTo(0, -radioSize);
    this.radioFilled.lineTo(radioSize, 0);
    this.radioFilled.lineTo(0, radioSize);
    this.radioFilled.lineTo(-radioSize, 0);
    this.radioFilled.lineTo(0, -radioSize);
    this.radioFilled.endFill();
    this.radioFilled.alpha = 0;
    // the options
    {
      let txt = new PIXI.Text(this.data.options[0].title, this.styleEventButton); // Option 1 title
      let txt1 = new PIXI.Text(this.data.options[1].title, this.styleEventButton); // Option 1 title
      txtSize = txt.width > txt1.width ? txt.width : txt1.width;
    }
    createButton = (buttonText)=> {
      let button = new PIXI.Container();
      button.title = buttonText;
      let txt = new PIXI.Text(buttonText, this.styleEventButton); // Option 1 title
      // let buttonWV = txtSize / 2 + buttonPadding;
      // let buttonHV = txt.height / 2 + buttonPadding;
      let buttonWV = 75;
      let buttonHV = 33;
      let tmp = new PIXI.Graphics();
      let tmp1 = new PIXI.Graphics();
      let tmp2 = new PIXI.Graphics();
      txt.position.x = -txt.width / 2;
      txt.position.y = -txt.height / 2;
      tmp.beginFill(0xB99F65);
      tmp.moveTo(-buttonWV-3, buttonHV+3);
      tmp.lineTo(-buttonWV-3, -buttonHV-3);
      tmp.lineTo(buttonWV+3, -buttonHV-3);
      tmp.lineTo(buttonWV+3, buttonHV+3);
      tmp.lineTo(-buttonWV-3, buttonHV+3);
      tmp1.beginFill(0x0C1F1D);
      tmp1.moveTo(-buttonWV, buttonHV);
      tmp1.lineTo(-buttonWV, -buttonHV);
      tmp1.lineTo(buttonWV, -buttonHV);
      tmp1.lineTo(buttonWV, buttonHV);
      tmp1.lineTo(-buttonWV, buttonHV);
      tmp2.beginFill(0x193C38);
      tmp2.moveTo(-buttonWV, buttonHV);
      tmp2.lineTo(-buttonWV, -buttonHV);
      tmp2.lineTo(buttonWV, -buttonHV);
      tmp2.lineTo(buttonWV, buttonHV);
      tmp2.lineTo(-buttonWV, buttonHV);
      tmp2.alpha = 0;

      button.addChild(tmp);
      button.addChild(tmp1);
      button.addChild(tmp2);
      button.addChild(txt);

      button.interactive = true;
      button.buttonMode = true;

      return button;
    };
    option1Data = this.data.options[0];
    option2Data = this.data.options[1];
    this.option1 = createButton(option1Data.title);
    this.option2 = createButton(option2Data.title);
    this.option1.isSelected = this.option2.isSelected = true;
    this.option1.position.y = -buttonDistance;
    this.option2.position.y = buttonDistance;

    // add them all
    container.addChild(line);
    container.addChild(this.leaderLineContainer);
    radio.addChild(this.radioStroke);
    radio.addChild(this.radioFilled);
    container.addChild(radio);
    container.addChild(this.option1);
    container.addChild(this.option2);
    this.addChild(container);

    // Set up the buttons
    // Radio button for centering the split
    radio.interactive = true;
    radio.buttonMode = true;
    radio.on('pointerdown', ()=> {
      this.clickIntention = true;
    });
    radio.on('pointerup', ()=> {
      if(this.clickIntention) this.select(true);
      this.clickIntention = false;
    });
    // Option buttons
    this.option1.on('pointerdown', ()=> {
      this.clickIntention = true;
    });
    this.option1.on('pointerup', ()=> {
      if(this.clickIntention) {
        this.select(true);
        this.gotoTimeline(this.option1, option1Data.goto);
      }
      this.clickIntention = false;
    });
    this.option2.on('pointerdown', ()=> {
      this.clickIntention = true;
    });
    this.option2.on('pointerup', ()=> {
      if(this.clickIntention) {
        this.select(true);
        this.gotoTimeline(this.option2, option2Data.goto);
      }
      this.clickIntention = false;
    });

    let that = this;

    let mouseOverRadio = function() {
      TweenMax.to(that.radioStroke.scale, 0.2, { y: scaleSize, x:  scaleSize});
      TweenMax.to(that.radioFilled.scale, 0.2, { y: scaleSize, x:  scaleSize});
    };

    let mouseOutRadio = function() {
      if(!that.selected ) {
        TweenMax.to(that.radioFilled.scale, 0.2, { y: 1, x:  1});
        TweenMax.to(that.radioStroke.scale, 0.2, { y: 1, x:  1});
      }
    };

    let mouseOver = function() {
      TweenMax.to(this.children[2], 0.2, { alpha: 1 });
      TweenMax.to(this.children[0], 0.2, { alpha: 1 });

      mouseOverRadio();
    };

    let mouseOut = function() {
      TweenMax.to(this.children[2], 0.5, { alpha: 0 });

      if( !this.isSelected ) {
        TweenMax.to(this.children[0], 0.5, { alpha: 0 });
      }

      mouseOutRadio();
    };

    this.option1.on('mouseover', mouseOver);
    this.option1.on('mouseout', mouseOut);
    this.option2.on('mouseover', mouseOver);
    this.option2.on('mouseout', mouseOut);
    radio.on('mouseover', mouseOverRadio);
    radio.on('mouseout', mouseOutRadio);
  }

  onDirectionMove(e) {
    if( !this.selected ) return;

    switch (e.detail.direction) {
      case 'up':
        this.gotoTimeline(this.option1, this.data.options[0].goto);
        break;
      case 'down':
        this.gotoTimeline(this.option2, this.data.options[1].goto);
        break;
      default:
        return;
    }
  }

  select(direct = false) {
    this.emitEvent('EventSelected', { event: this, direct: direct });
    this.selected = true;
  }

  set selected(value) {
    let obj = { alpha: 1, scale: scaleSize };
    let endObj = { alpha: 0, scale: 1 };
    let change = false;
    if( this.radioFilled ) {
      if( this.selected === false && value === true ) {
        this.radioFilled.scale.x = this.radioFilled.scale.y = scaleSize;
        obj.scale = scaleSize;
        obj.alpha = 0;
        endObj.scale = scaleSize;
        endObj.alpha = 1;
        change = true;
      } else if( this.selected === true && value === false ) {
        change = true;
      }
      if( change ) {
        TweenMax.to(obj, 0.5, {
          scale: endObj.scale,
          alpha: endObj.alpha,
          onUpdate: ()=> {
            this.radioStroke.scale.x = this.radioStroke.scale.y = obj.scale;
            this.radioFilled.scale.x = this.radioFilled.scale.y = obj.scale;
            this.radioFilled.alpha = obj.alpha;
          }
        });
      }
    }
    this._selected = value === true;
    this.data.selected = this._selected;
  }
  get selected() {
    return this._selected === true;
  }

  /**
   * gotoTimeline - This adds a timeline in response to a split button press
   *
   * @public
   * @todo The drawing functionality here should be moved out of this function and into a listener for Event#TimelineSelected
   * @param  {PIXI.Sprite}  button       The button that was clicked on to spawn this timeline
   * @param  {String}       timelineName the name of the timeline to spawn
   * @return {undefined}
   */
  gotoTimeline(button, timelineName) {
    if( this.leaderLine && this.leaderLine.parent ) {
      this.leaderLine.parent.removeChild(this.leaderLine);
    }

    let drawLine = function(x, y, length, direction = 1) {
      let g = new PIXI.Graphics();
      g.beginFill(0xB99F65);
      if(direction === 1) {
        g.drawRect(x, y-3, length, 6);
      } else {
        g.drawRect(x-3, y, 6, length);
      }
      return g;
    };

    this.leaderLine = new PIXI.Container();
    let x = button.position.x;
    let y = button.position.y;
    this.leaderLine.addChild(drawLine(x, y, button.width / 2 + 50));
    x += button.width / 2 + 50;
    y -= 3;
    if( y > 0 ) y += 6; // This accounts for lines that come out of the lower buttons
    this.leaderLine.addChild(drawLine(x, y, -y, 2));
    y = 0;
    x -= 3;
    this.leaderLine.addChild(drawLine(x, y, 50));

    this.leaderLineContainer.addChild(this.leaderLine);

    this.data.timelineName = timelineName;
    this.data.timelineSelected = true;

    this.option1.isSelected = this.option2.isSelected = false;
    button.isSelected = true;

    this.option1.children[0].alpha = this.option1 === button ? 1 : 0;
    this.option2.children[0].alpha = this.option2 === button ? 1 : 0;

    this.emitEvent('TimelineSelected', { button: button, title: button.title, timelineName: timelineName });
  }

  get absCentre() {
    let absCentre = this.toGlobal(new PIXI.Point(0,0));
    return absCentre;
  }

  onEventSelected(e) {
    if(e.detail.event !== this) {
      this.selected = false;
    }
  }

  onEventUnselected(e) {
  }

  onTimelineFoundEvent(e) {
    if(e.detail.eventObj.event === this) {
      this.select();
    }
  }

  onTimelineDrag(e) {
    this.clickIntention = false;
  }
}

export default Event;
