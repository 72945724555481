import _u from "wtc-utility-helpers";
import {
  default as ElementController,
  ExecuteControllers
} from "wtc-controller-element";
import Modal from "wtc-modal-view";
import CONFIG from "../config";
import Tracking from "../Nintendo/Tracking";

class GlossaryModal extends Modal {
  constructor() {
    super();
  }
}

class Glossary extends ElementController {
  constructor(element) {
    super(element);

    this.data = {};
    this.showList = null;
    this.imagePath = null;
    this._detailType = null;
    this._returnTo = null;

    this._detailIndex = 0;
    this._detailSelected = {};
    this._detailPrevIndex = 0;
    this._detailPrev = {};
    this._detailNextIndex = 0;
    this._detailNext = {};

    this._touchstartX = 0;
    this._touchstartY = 0;
    this._touchendX = 0;
    this._touchendY = 0;
    this._pageScroll = null;

    this.announcer = $(".glossary-announcer");
    this.announcer.html("Loading glossary");

    // Set image path
    this.imagePath =
      element.getAttribute("data-basepath") + "assets/img/gallery-assets/";

    //modal template
    this.modalTemplate = `
      <div class="glossary">
        <div class="glossary--detail-content"></div>
        <nav class="glossary--nav" aria-label="Glossary modal">
          <button class="glossary--nav-prev" data-metric="{ eVars:{39:'button:previous'}, events:[53] }">
            <span class="visually-hidden">Previous</span>
            <span aria-hidden="true" class="icon--arrow-left"></span>
            <img />
          </a>
          <button class="glossary--nav-next" data-metric="{ eVars:{39:'button:next'}, events:[53] }">
            <span class="visually-hidden">Next</span>
            <span aria-hidden="true" class="icon--arrow-right"></span>
            <img />
          </a>
        </nav>
      </div>`;

    this.$details = $(this.modalTemplate);

    Modal.onClose = () => {
      $("body").removeClass("modal__glossary is-open");

      nclood.Metrics.trackLink(
        {
          eVars: { 39: "button:close" },
          events: [53]
        },
        null
      );

      if (this._returnTo) {
        this._returnTo.focus();
        this._returnTo = null;
      }
    };

    Modal.onOpen = function() {
      $("body").addClass("modal__glossary is-open");
    };

    // Retrieve jSON data file
    this.getData();
  }

  _buildGlossaryList() {
    var op = this;

    if (this.showList !== null) {
      var group = this.data[this.showList];

      if (group.length > 0) {
        for (var i = 0; i < group.length; i++) {
          var $image = $("<img />").attr({
            alt: group[i].meta,
            src: op.imagePath + group[i].images.thumb
          });
          // var srcset = (group[i].images.thumb2 ? op.imagePath + group[i].images.thumb2 + ' 1x' : '') + (group[i].images.thumb2 ? ', ' + op.imagePath + group[i].images.thumb2x + ' 2x' : '');
          var srcset =
            (group[i].images.thumboff
              ? op.imagePath + group[i].images.thumboff + " 1x"
              : "") +
            (group[i].images.thumboff
              ? ", " + op.imagePath + group[i].images.thumboff
              : "");
          $image.attr("srcset", srcset);

          var evar, evardata, event;
          evardata = group[i].metric;
          if (op.showList == "characters") {
            evar = 8;
            event = 11;
          } else if (op.showList == "items") {
            evar = 39;
            event = 53;
          }

          var $link = $("<button />")
            .attr({
              "data-group": op.showList,
              "data-index": i,
              "data-id": group[i].id,
              "data-evar": evar,
              "data-evardata": evardata,
              "data-event": event
            })
            .append($image);
          var $item = $("<li />")
            .addClass("column-flex-2 character")
            .attr({
              "data-group": op.showList
            })
            .append($link);

          // Add image to list
          $(".characters-list").append($item);
        }
      }
    }
  }

  _buildGlossaryDetail() {
    var op = this;
    // parent element
    // var $detail = $('.glossary--detail-wrapper .glossary--detail');
    // var $content = $detail.find('.glossary--detail-content');

    // parent element
    op.$details = $(op.modalTemplate);
    var $detail = op.$details;

    var $content = $detail.find(".glossary--detail-content");

    var $prev = $detail.find(".glossary--nav-prev");
    var $prevImg = $("<img />").attr({
      alt: op._detailPrev.meta ? op._detailPrev.meta : op._detailPrev.name,
      src: op.imagePath + op._detailPrev.images.thumb
    });
    var srcset =
      (op._detailPrev.images.thumb
        ? op.imagePath + op._detailPrev.images.thumb2x + " 1x"
        : "") +
      (op._detailPrev.images.thumb2x
        ? ", " + op.imagePath + op._detailPrev.images.thumb2x + " 2x"
        : "");
    $prevImg.attr("srcset", srcset);

    $prev.find("img").replaceWith($prevImg);
    $prev.attr({
      "data-group": op._detailType,
      "data-index": op._detailPrevIndex
    });

    var $next = $detail.find(".glossary--nav-next");
    var $nextImg = $("<img />").attr({
      alt: op._detailNext.meta ? op._detailNext.meta : op._detailNext.name,
      src: op.imagePath + op._detailNext.images.thumb
    });
    srcset =
      (op._detailNext.images.thumb
        ? op.imagePath + op._detailNext.images.thumb2x + " 1x"
        : "") +
      (op._detailNext.images.thumb2x
        ? ", " + op.imagePath + op._detailNext.images.thumb2x + " 2x"
        : "");
    $nextImg.attr("srcset", srcset);

    $next.find("img").replaceWith($nextImg);
    $next.attr({
      "data-group": op._detailType,
      "data-index": op._detailNextIndex
    });

    var $imgCol = $("<div />").addClass("glossary--image");
    var $primaryImg = $("<img />").attr({
      alt: op._detailSelected.meta
        ? op._detailSelected.meta
        : op._detailSelected.name,
      src: op.imagePath + op._detailSelected.images.primary
    });
    srcset =
      (op._detailSelected.images.primary
        ? op.imagePath + op._detailSelected.images.primary + " 1x"
        : "") +
      (op._detailSelected.images.primary2x
        ? ", " + op.imagePath + op._detailSelected.images.primary2x + " 2x"
        : "");
    $primaryImg.attr("srcset", srcset);
    $imgCol.append($primaryImg);

    var $metaCol = $("<div />").addClass("glossary--detail-content__wrap");
    $metaCol.append($("<h3 />").html(op._detailSelected.name));
    $metaCol.append($("<p />").html(op._detailSelected.desc));
    if (op._detailSelected.meta.length > 0) {
      $metaCol.append(
        $('<div class="footer" aria-hidden="true" />').append(
          $("<p />").html(op._detailSelected.meta)
        )
      );
    }

    $metaCol.wrapInner("<div></div>"); // add flex wrapper

    $content.html("").append($imgCol, $metaCol);

    if ($("body").hasClass("modal__glossary")) {
      $(".modal .modal__content").html($detail);
    } else {
      Modal.open($detail[0]);

      $(document).keyup(function(e) {
        if (e.keyCode === 27) op._closeModalView();
      });
    }

    setTimeout(function() {
      $(".glossary--detail-content").addClass("is-visible");
      op.announcer.html("Loaded");
    }, 150);

    // $('.glossary--detail-content').css( 'opacity', 1 );
  }

  getData() {
    var dataUrl = $(".section--glossary").attr("data-datafile");
    var parsed, request;
    request = new XMLHttpRequest();
    request.overrideMimeType("application/json");
    request.open("GET", dataUrl, false);
    request.send();

    if (request.status >= 200 && request.status < 400) {
      parsed = JSON.parse(request.responseText);
      this.data = parsed.glossary;

      this.showList = $(".characters-list").attr("data-show-group");
      this.announcer.html("Glossary loaded");
      this._buildGlossaryList();

      this.characterFilterClickHandler();
      this.detailClickHandler();
      this.modalClickHandler();
      this.modalClickNavHandler();
      this.swipeListener();

      // this.viewAll();

      // $('.blur--wrapper').scroll(function(event){
      // });
    }

    // this.data = this._loadDatafile(dataUrl);
  }

  characterFilterClickHandler() {
    var op = this;
    $(document).on("click", ".character-filters li button", function(e) {
      let $parent = $(this).closest("li");

      if (!$parent.hasClass("active")) {
        // Track filter click
        let evar = $(this).attr("data-evardata");
        Tracking.track({
          eVars: { 39: evar },
          events: [53]
        });

        $parent
          .parent()
          .find(".active")
          .removeClass("active");
        $(this)
          .closest("li")
          .addClass("active");

        op.showList = $(this).attr("data-group");

        if (
          $('.characters-list .character[data-group="' + op.showList + '"]')
            .length < 1
        ) {
          // Only build if the characters haven't already been loaded.
          op._buildGlossaryList();
        }

        $(".characters-list").attr("data-show-group", op.showList);
      }

      $(".glossary-filter-announcer span").html($(this).html());

      nclood.Metrics.trackLink(
        {
          eVars: { 39: "button: characters" + gameName.replace("-", " ") },
          events: [53]
        },
        e
      );

      return false;
    });
  }

  _loadDetail() {
    var op = this;

    // $('#glossary--characters-list', this.$element).addClass('transition--out');
    // var $wrapper = $('.glossary--detail-wrapper', this.$element);
    // $('.glossary--detail-content').css( 'opacity', 0 );

    op.announcer.html(
      "Loading " + op.data[op._detailType][op._detailIndex].name
    );

    op._preloadDetailImages(op._detailType, op._detailIndex);

    setTimeout(function() {
      // group size
      var groupMax = op.data[op._detailType].length - 1;

      // get selected element
      op._detailSelected = op.data[op._detailType][op._detailIndex];

      // get prev
      op._detailPrev = op.data[op._detailType][parseInt(op._detailIndex - 1)];
      op._detailPrevIndex = parseInt(op._detailIndex - 1);
      if (op._detailIndex === 0) {
        op._detailPrev = op.data[op._detailType][groupMax];
        op._detailPrevIndex = groupMax;
      }

      // get next
      op._detailNext = op.data[op._detailType][parseInt(op._detailIndex) + 1];
      op._detailNextIndex = parseInt(op._detailIndex) + 1;
      if (op._detailIndex == groupMax) {
        op._detailNext = op.data[op._detailType][0];
        op._detailNextIndex = 0;
      }
      $(".glossary--detail-content").removeClass("is-visible");
      op._buildGlossaryDetail();
    }, 500);

    // $wrapper.addClass('reveal');
  }

  detailClickHandler() {
    var op = this;

    $(document).on("click", ".characters-list button", function(e) {
      op.showList = $(this)
        .closest(".characters-list")
        .attr("data-show-group");
      op._returnTo = $(this);

      // Track character/item click
      let evarData = $(this).attr("data-evardata"),
        evar = parseInt($(this).attr("data-evar")),
        event = parseInt($(this).attr("data-event")),
        opts = { eVars: {}, events: [] };

      opts.eVars[evar] = evarData;
      opts.events.push(event);
      Tracking.track(opts);

      // type of element (characters/places/items) (data-group)
      op._detailType = $(this).attr("data-group");
      // index of click element (data-index)
      op._detailIndex = parseInt($(this).attr("data-index"));

      if ($(this).closest(".modal__content").length > 0) {
        op._closeModalView();
      }

      op._loadDetail();

      return false;
    });
  }

  // _displayModalList() {
  //   var op = this;

  //   $('body').addClass('modal__glossary is-open');
  //   // this.modal.clearVariables();

  //   // Update glossary nav
  //   $('#glossary--characters-list', this.$element).find('.character-filters .active').removeClass('active');
  //   $('#glossary--characters-list', this.$element).find('.character-filters a[data-group="'+op.showList+'"]').closest('li').addClass('active');

  //   var glossaryListElement = $('#glossary--characters-list', this.$element).prop('outerHTML');

  //   Modal.open(glossaryListElement);

  //   $(document).keyup(function(e) {
  //     if (e.keyCode === 27) op._closeModalView();   // esc
  //   });
  // }

  _closeModalView() {
    // close modal
    Modal.close();

    // If a page scroll is set on close scroll the page to the position.
    if (Glossary._pageScroll > 0) {
      document.body.scrollTop = Glossary._pageScroll;
    }
  }

  // viewAll() {
  //   var op = this;

  //   $('.glossary--view-all', this.$element).on('click', function() {

  //     // Save scroll position for iOS devices to be able to scroll page on modal close.
  //     if (document.documentElement.classList.contains("alps-os-ios"))
  //     {
  //       Glossary._pageScroll = window.pageYOffset;
  //     }

  //     op._displayModalList();

  //     return false;
  //   });
  // }

  modalClickHandler() {
    var op = this;
    $(document).on("click", ".glossary--view-all-close", function() {
      nclood.Metrics.trackLink(
        {
          eVars: { 39: "button:close" + gameName.replace("-", " ") },
          events: [53]
        },
        e
      );
      op._closeModalView();

      return false;
    });
  }

  modalClickNavHandler() {
    var op = this;

    $(document).on(
      "click",
      ".glossary--nav-prev, .glossary--nav-next",
      function() {
        // type of element (characters/places/items) (data-group)
        op._detailType = $(this).attr("data-group");
        // index of click element (data-index)
        op._detailIndex = parseInt($(this).attr("data-index"));

        op._loadDetail();

        return false;
      }
    );
  }

  swipeListener() {
    var op = this;
    var detail = op.$details.get(0);

    detail.addEventListener(
      "touchstart",
      function(event) {
        op._touchstartX = event.changedTouches[0].screenX;
        op._touchstartY = event.changedTouches[0].screenY;
      },
      false
    );

    detail.addEventListener(
      "touchend",
      function(event) {
        op._touchendX = event.changedTouches[0].screenX;
        op._touchendY = event.changedTouches[0].screenY;
        op.swipeGesture();
      },
      false
    );
  }

  swipeGesture() {
    var op = this;
    var $next;

    // omit vertical swipes
    if (
      parseInt(op._touchstartX - op._touchendX) > 150 ||
      parseInt(op._touchstartX - op._touchendX) < -150
    ) {
      // Next
      if (this._touchendX < this._touchstartX) {
        $next = $(document).find(".glossary--nav-next");
      }

      // Prev
      if (this._touchendX > this._touchstartX) {
        $next = $(document).find(".glossary--nav-prev");
      }

      if ($next.length > 0) {
        op._detailType = $next.attr("data-group");
        // index of click element (data-index)
        op._detailIndex = $next.attr("data-index");

        op._loadDetail();
      }
    }
  }

  _preloadDetailImages(type, index) {
    console.log(3);
    var op = this;
    var imgRes = "primary";
    var groupMax = op.data[type].length - 1;

    // Check if is retina screen
    if (op.isRetina() === true) {
      imgRes = "primary2x";
    }

    // Load current
    op._preloadImage(op.imagePath + op.data[type][index].images[imgRes]);

    // load previous two
    var prev = parseInt(index);
    for (var i = 0; i < 2; i++) {
      if (prev === 0) {
        prev = groupMax;
      } else {
        prev--;
      }
      // Preload
      op._preloadImage(op.imagePath + op.data[type][prev].images[imgRes]);
    }

    // load next two
    var next = index;
    for (var i = 0; i < 2; i++) {
      if (next >= groupMax) {
        next = 0;
      } else {
        next++;
      }
      // Preload
      op._preloadImage(op.imagePath + op.data[type][next].images[imgRes]);
    }
  }

  _preloadImage(src) {
    var image = new Image();
    image.src = src;

    return image.complete;
  }

  isRetina() {
    var mediaQuery = `(-webkit-min-device-pixel-ratio: 1.5),
          (min--moz-device-pixel-ratio: 1.5),
          (-o-min-device-pixel-ratio: 3/2),
          (min-resolution: 1.5dppx)`;

    if (window.devicePixelRatio > 1) return true;
    if (window.matchMedia && window.matchMedia(mediaQuery).matches) return true;
    return false;
  }
}

ExecuteControllers.registerController(Glossary, "Glossary");

export default Glossary;
