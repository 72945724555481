import DisplayObject from './DisplayObject';

const paddingLeft = 10;
const paddingTop = 10;

class Label extends DisplayObject {
  constructor(text) {
    super();

    this.text = text;

    this.init();
  }
  init() {
    let style = this.styleLabel;
    let text = new PIXI.Text(this.text, style);
    text.position.x = paddingLeft;
    text.position.y = paddingTop;

    // draw a rectangle
    let graphics = new PIXI.Graphics();
    graphics.beginFill(0xB99F65);
    graphics.drawRect(0, 0, text.width + paddingLeft * 2, text.height + paddingTop * 2);
    this.addChild(graphics);

    this.addChild(text);
  }
}

export default Label;
