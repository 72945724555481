import _u from "wtc-utility-helpers";
import {
  default as ElementController,
  ExecuteControllers,
} from "wtc-controller-element";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import Modal from "wtc-modal-view";

const cloudinaryEmbedUrl = "https://player.cloudinary.com/embed/";

const modalClassName = "modal--news"
const bodyClassName = "news-modal-open"

const richTextOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const entry = node.data.target;
      if (entry.__type === "Media") {
        if (entry.asset.resourceType === "image") {
          return `<img width="100%" height="auto" src="${entry.asset.url}" alt="">`;
        }
        if (entry.asset.resourceType === "video") {
          const embedSrc = new URL(cloudinaryEmbedUrl);
          embedSrc.searchParams.set("cloud_name", "ncom");
          embedSrc.searchParams.set("public_id", entry.asset.publicId);
          embedSrc.searchParams.set("cloudinary[cname]", "assets.nintendo.com");
          embedSrc.searchParams.append("source[source_types]", "mp4/h265");
          embedSrc.searchParams.append("source[source_types]", "mp4");
          return `
            <iframe
              src="${embedSrc.toString()}"
              width="100%"
              style="aspect-ratio: 16/9"
              allow="fullscreen"
              frameBorder="0"
            ></iframe>
          `;
        }
      }
    },
  },
};

function resolveEmbeddedLinks(node, links) {
  const entryId =
    node.data.target && node.data.target.sys && node.data.target.sys.id;
  if (node.nodeType === BLOCKS.EMBEDDED_ENTRY && entryId) {
    node.data.target = links.entries.block.find(({ id }) => entryId === id);
  } else if (node.content) {
    for (const child of node.content) {
      resolveEmbeddedLinks(child, links);
    }
  }
  return node;
}

function newsArticleRichText({ json, links }) {
  if (json.nodeType === "document") {
    const document = resolveEmbeddedLinks(json, links);
    return documentToHtmlString(document, richTextOptions);
  }
  return null;
}

const dateFormat = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
};

const query = `#graphql
query NewsArticleBySlugForZeldaPortal($locale: Locale!, $slug: String!) {
	article: newsArticle(locale: $locale, slug: $slug) {
		id
		locale
		title
		publishDate
		contentRating {
			code
			label
		}
		contentDescriptors {
			label
			type
		}
		media {
			url(transforms: [{
				resize: { width: "903" }
				dpr: "auto"
			}])
		}
		body {
			json
			links {
				entries {
					block {
						type: __typename
						id
						locale
						... on Media {
							asset {
								publicId
								resourceType
								url(transforms: [{
									resize: { width: "738" }
									dpr: "auto"
								}])
							}
						}
					}
				}
			}
		}
		disclaimers {
			json
		}
	}
}
`;

class NewsModal extends Modal {
  constructor() {
    super();
  }
}

class NewsArticle {
 
  constructor() {
    this.locale = document.documentElement.lang;
    this.variables = {
      locale: document.documentElement.lang.replace("-", "_"),
    };

    this.onHashChange = this.onHashChange.bind(this, true);
    this.onHashChange();

    window.addEventListener("hashchange", this.onHashChange);

    NewsModal.onClose = () => {
      document.body.classList.remove(bodyClassName);

      history.pushState(null, '', '/news/');    

      if (this._returnTo) {
        this._returnTo.focus();
        this._returnTo = null;
      }
    };

    NewsModal.onOpen = () => {
      const modalContent = document.querySelector(".modal__content");
      modalContent.scrollTop = 0;
      document.body.classList.add(bodyClassName);
    };
  }

  async onHashChange() {
    const slug = window.location.hash.substring(1);

    if (slug !== this.variables.slug) {
      this.variables.slug = slug;
      await this.render();
    }
  }

  async render() {
    try {
      const article = await this.fetchArticle();
      if (article) {
        const content = this.createArticleElem(article)
        NewsModal.open(content, modalClassName);
      } else {
        throw new Error(
          `Unable to fetch article for ${JSON.stringify(this.variables)}`
        );
      }
    } catch (err) {
      console.warn(err);
    }
  }

  async fetchArticle() {
    if (!this.variables.slug) return null;
    const url = new URL("https://graph.nintendo.com/");
    url.searchParams.set("query", query);
    url.searchParams.set("variables", JSON.stringify(this.variables));
    url.searchParams.set("operationName", "NewsArticleBySlugForZeldaPortal");

    const res = await fetch(url);
    const { data, errors } = await res.json();
    if (data && data.article) return data.article;
    throw new Error(
      `Failed to fetch article due to: ${JSON.stringify(errors)}`
    );
  }

  createArticleElem(article) {
    const {
      title,
      body,
      disclaimers,
      publishDate,
      media,
      contentRating,
      contentDescriptors,
    } = article;
    var allContentDescriptors;
    var allInteractiveElements;
    if (contentDescriptors.length) {
      // Splitting content descriptors into CONTENT_DESCRIPTOR & INTERACTIVE_ELEMENT categories
      let descriptors = contentDescriptors.reduce((r, a) => {
        r[a.type] = [...(r[a.type] || []), a];
        return r;
      }, {});

      //if there are CONTENT_DESCRIPTORS, map through to create string to pass to esrb-rating "descriptors" attribute
      if (descriptors.CONTENT_DESCRIPTOR) {
        allContentDescriptors =
          ("descriptors",
          descriptors.CONTENT_DESCRIPTOR.map(
            (descriptor) => descriptor.label
          ).join(", "));
      }

      // if there are INTERACTIVE_ELEMENTS, map through to create string to pass to esrb-rating "descriptors" attribute
      if (descriptors.INTERACTIVE_ELEMENT) {
        allInteractiveElements =
          ("interactive-elements",
          descriptors.INTERACTIVE_ELEMENT.map(
            (descriptor) => descriptor.label
          ).join(", "));
      }
    }
    // console.log(allContentDescriptors);
    // console.log(allInteractiveElements);
    const localizedDate = new Date(publishDate).toLocaleDateString(
      this.locale,
      dateFormat
    );
    const elem = document.createElement("div");
    elem.classList.add("news-modal-outer");
    elem.innerHTML = `
      <div class="news-modal-inner">
        <div class="news-article--header"></div>
        <div class="news-article--content section__border-gradient section__border-gradient--wide">
          <div class="triforce-border triforce-border--toponly"></div>
          <div class="row-flex news-article--content__container">
            <div class="column-flex column-flex-12 column-flex-large-10 news-article--img__container">
              <img class="news-article-wc__header-image img-full" alt="" src="${
                media.url ? media.url : ""
              }">
            </div>
            <div class="column-flex column-flex-10 column-flex-large-8">
              <h1 class="text-center news-article-title">${title}</h1>
              <p class="news-article-wc__date">${localizedDate}</p>
              ${
                body
                  ? `<div class="news-article-wc__body">${newsArticleRichText(
                      body
                    )}</div>`
                  : ""
              }
              ${
                disclaimers
                  ? `<div class="news-article-wc__disclaimers">${newsArticleRichText(
                      disclaimers
                    )}</div>`
                  : `<hr>`
              }

              ${
                contentRating
                  ? `<div class="esrb-container">
                  <esrb-rating rating="${contentRating.code}" alt="${
                      contentRating.label
                    }" descriptors="${
                      allContentDescriptors ? allContentDescriptors : ""
                    }" interactive-elements="${
                      allInteractiveElements ? allInteractiveElements : ""
                    }"></esrb-rating>
                </div>`
                  : ""
              }
            </div>
          </div>
        </div>
      </div>
		`;

    return elem;
  }
}

ExecuteControllers.registerController(NewsArticle, "NewsArticle");

export default NewsArticle;
