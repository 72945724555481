import _u from 'wtc-utility-helpers';

let url_language,
    body = document.body,
    docroot = 'https://www.zelda.com/',
    CONFIG = {},
    uA = navigator.userAgent;

// Get the document language
if (_u.hasClass('lang-es', body)) {
  url_language = 'es';
} else if (_u.hasClass('lang-fr', body)) {
  url_language = 'fr';
} else {
  url_language = 'en';
}

let isIE10, isEdge, isIE11, isWiiU, isIos, iosVersion, isAndroid, isSafari, is_chrome, is_safari = false;

body.setAttribute('data-useragent', uA);

isIE10 = RegExp('msie' + ('\\s10'), 'i').test(uA);
if (isIE10) {
  _u.addClass('is-ie10', body);
}

isIE11 = navigator.userAgent.match(/trident/gi);
if (isIE11) {
  _u.addClass('is-ie11', body);
}

isEdge = /Edge\//.test(uA);
if (isEdge) {
  _u.addClass('is-edge', body);
}

isWiiU = /WiiU\//.test(uA);
if (isWiiU) {
  _u.addClass('is-wiiu', body);
}

isIos = /iP(hone|od|ad)/.test(uA);
if (isIos) {
  _u.addClass('is-ios', body);

  // get ios version
  // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
  let v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
  iosVersion = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)][0];
}

isAndroid = /Android/.test(uA);
if (isAndroid) {
  _u.addClass('is-android', body);
}

// Class for nasty hacks
is_chrome = uA.indexOf('Chrome') > -1;
is_safari = uA.indexOf("Safari") > -1;
if (is_safari && !is_chrome) {
  isSafari = true;
  _u.addClass('is-safari-only', body);
}

CONFIG.CONST = {
  ENV: 'DEPLOY',
  ISIE10: isIE10,
  ISEDGE: isEdge,
  ISWIIU: isWiiU,
  ISIOS: isIos,
  IOSVERSION: iosVersion,
  ISANDROID: isAndroid,
  ISSAFARI: isSafari,
  DOCROOT: docroot,
  ASSETS: docroot + 'assets/',
  LANGUAGE: url_language,
  VIDEOS: {
  },
  VIDEOS_R: {},
  PLATFORM: "switch",
  SUITE: {
    EN: "ncomglobal,ncomzeldauni",
    FR: "ncomglobal,ncomzeldauni",
    ES: "ncomglobal,ncomzeldauni"
  },
  SITENAME: "zelda universe",
  SITETYPE: "portal",
  PRODUCTNAME: "",
  GENRE: "",
  PUBLISHER: "",
  PRODUCTCODE: "",
  PRODUCTID: ""
};

export default CONFIG;