import _u from "wtc-utility-helpers";
import TimelineCollection from "../Models/TimelineCollection";
import Timeline from "../Models/Timeline";
import Event from "../Models/Event";
import Era from "../Models/Era";
import TimelineParser from "../TimelineParser";

// https://gist.github.com/gordonbrander/2230317
function IDGenerator() {
  return Math.random()
    .toString(36)
    .substr(2, 9);
}

function cleanTitle(text) {
  let newText = text.replace('<br class="hide-br">', "\r");
  newText = newText.replace("<br>", " ");

  return newText;
}

function cleanAlt(text) {
  let newText = text.replace('<br class="hide-br">', "");
  newText = newText.replace("<br>", " ");

  return newText;
}

let timelineCollection = new TimelineCollection({ id: "main" });
let timelineInstances = [];
let screens = [];

let makeTimelineScreen = function(node, id) {
  let screenWrapper = null;

  if (node.type == "timeline") {
    screenWrapper = document.createElement("div");
    _u.addClass(
      "timeline-screen timeline-screen--timeline row-flex",
      screenWrapper
    );
    screenWrapper.setAttribute("data-id", id);
    screenWrapper.setAttribute("aria-hidden", "true");

    screenWrapper.innerHTML = `
      <div class="timeline-screen__title-wrapper"><h3 class="h1 timeline-screen__title">${node.title}</h3></div>
    `;

    let screenGames = document.createElement("ul");
    screenGames.setAttribute("aria-label", "Games");
    _u.addClass(
      "timeline-screen--timeline__games row-flex" +
        (node.games.length < 5 ? " column-flex-12 column-flex-large-10" : ""),
      screenGames
    );
    for (let game of node.games) {
      let gameWrapper = document.createElement("li");
      gameWrapper.innerHTML = `
        <button class="timeline-screen--timeline__game" aria-hidden="true" tabindex="-1" data-id="${game.id}">
          <img class="timeline-screen--timeline__game-thumbnail" src="${game.thumbnail.src}" srcset="${game.thumbnail.srcset}" />
          <p class="timeline-screen--timeline__game-title"><span>${game.title}</span></p>
        </button>
      `;
      gameWrapper
        .querySelector(".timeline-screen--timeline__game-thumbnail")
        .setAttribute("alt", "");
      screenGames.appendChild(gameWrapper);
    }

    screenWrapper.appendChild(screenGames);
  } else if (node.type == "game") {
    screenWrapper = document.createElement("div");
    _u.addClass(
      "timeline-screen timeline-screen--game row-flex",
      screenWrapper
    );
    screenWrapper.setAttribute("data-id", id);
    screenWrapper.setAttribute("aria-hidden", "true");

    screenWrapper.innerHTML = `
      <div class="text-center timeline-screen--game__description-wrapper column-flex-4">
        <h3><img src="${node.logo.src}" srcset="${
      node.logo.srcset
    }" alt="${cleanAlt(node.fullTitle)}" /></h3>
        <div class="timeline-screen--game__description">
          ${node.description}
        </div>
        ${
          node.href
            ? '<a aria-hidden="true" tabindex="-1" class="timeline-screen--game__link" href="' +
              node.href +
              '" target="_blank" title="Visit the website"><span aria-hidden="true">Visit the Official Site</span><span class="visually-hidden">Visit the Official Site for ' +
              cleanAlt(node.fullTitle) +
              "</span></a>"
            : ""
        }
      </div>
      <div class="timeline-screen--game__img column-flex-7">
        <img src="${node.thumbnail.src}" srcset="${
      node.thumbnail.srcset
    }" alt="" />
      </div>
    `;
  } else if (node.type == "split") {
    screenWrapper = document.createElement("div");
    _u.addClass(
      "timeline-screen timeline-screen--split text-center",
      screenWrapper
    );
    screenWrapper.setAttribute("data-id", id);
    screenWrapper.setAttribute("aria-hidden", "true");

    screenWrapper.innerHTML = `
      <h3 class="h1 timeline-screen__title">${node.title}</h3>
      <p class="timeline-screen__description">${node.description}</p>
      <ul class="timeline-screen__split-options" aria-label="Choose a path" tabindex="-1">
        ${node.options
          .map(
            splitNode => `
          <li>
            <button data-id="${id}" data-option="${splitNode.id}" data-number="${splitNode.optionNumber}" tabindex="-1" aria-hidden="true">
              ${splitNode.title}
            </button>
          </li>
        `
          )
          .join("")}
      </ul>
    `;
  }

  if (screenWrapper) screens.push(screenWrapper);

  return screenWrapper;
};

let createTimeline = function(timelineArray = null, relatedId = null) {
  let previousNode = {
    type: null,
    el: null
  };
  let maintl = {
    id: IDGenerator()
  };
  if (relatedId) maintl.relatedTo = relatedId;

  let currentInstance = new Timeline(maintl);

  for (let node of timelineArray) {
    if (node.type == "timeline") {
      currentInstance.title = cleanTitle(node.title);
      let screen = makeTimelineScreen(node, currentInstance.id);

      previousNode = {
        type: node.type,
        el: null
      };
    } else if (node.type == "placeholder") {
      let id = IDGenerator();
      let screen = makeTimelineScreen(node, id);

      let placeholder = new Event({
        id: id,
        type: "placeholder",
        relation: currentInstance.getEraById(previousNode.el.id)
      });

      currentInstance.addEvent(placeholder);

      previousNode = {
        type: node.type,
        el: placeholder
      };
    } else if (node.type == "events") {
      let id = IDGenerator();
      let screen = makeTimelineScreen(node, id);

      let event = {
        id: id,
        type: "event",
        text: ""
      };

      if (previousNode.type == "era") {
        event.relation = currentInstance.getEraById(previousNode.el.id);
      }

      let text = "";
      for (let event of node.events) {
        if (text) text += " / ";

        text += cleanTitle(event);
      }

      event.text = text;

      let newEvent = new Event(event);
      currentInstance.addEvent(newEvent);

      previousNode = {
        type: node.type,
        el: event
      };
    } else if (node.type == "game") {
      let id = node.id;
      let screen = makeTimelineScreen(node, id);

      let game = {
        id: id,
        type: "game",
        title: cleanTitle(node.title),
        text: []
      };

      if (previousNode.type == "era") {
        game.relation = currentInstance.getEraById(previousNode.el.id);
      }

      let text = "";
      for (let event of node.events) {
        if (text) text += " / ";

        text += cleanTitle(event);
      }

      game.text.push(text);

      let newEvent = new Event(game);
      currentInstance.addEvent(newEvent);

      previousNode = {
        type: node.type,
        el: newEvent
      };
    } else if (node.type == "era") {
      let id = IDGenerator();
      let screen = makeTimelineScreen(node, id);

      let era = new Era({
        id: id,
        title: cleanTitle(node.title)
      });
      currentInstance.addEra(era);

      previousNode = {
        type: node.type,
        el: era
      };
    } else if (node.type == "split") {
      let id = IDGenerator();
      let screen = makeTimelineScreen(node, id, currentInstance.id);

      let newSplit = {
        id: id,
        title: node.title,
        type: "split",
        options: []
      };

      for (let i = 1; i <= node.options.length; i++) {
        const option = node.options[i - 1];
        let newOption = {
          title: cleanTitle(option.title),
          goto: createTimeline(option.timeline, currentInstance.id),
          parentTimeline: currentInstance.id,
          id: option.id,
          number: option.optionNumber
        };

        newSplit.options.push(newOption);
      }

      let newEvent = new Event(newSplit);
      currentInstance.addEvent(newEvent);

      previousNode = {
        type: node.type,
        el: newEvent
      };
    }
  }

  timelineInstances.push(currentInstance);

  return currentInstance.id;
};

let build = function() {
  let parsedtl = TimelineParser();
  createTimeline(parsedtl);

  for (let i = timelineInstances.length - 1; i >= 0; i--) {
    timelineCollection.addTimeline(timelineInstances[i]);
  }

  return {
    collection: timelineCollection,
    screens: screens
  };
};

export { build as default, IDGenerator };
