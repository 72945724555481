import Record from './Record'
import TimelineModel from './Timeline';

let instance = null;

class Timelinecollection extends Record {
  constructor(data) {
    super(data);

    window.collection = this;

    this.timelines = [];
  }

  addTimeline(timeline) {
    if(timeline instanceof TimelineModel) {
      this.timelines.push(timeline);
    }
  }

  findEventNearMiddle() {
    let lastDistance = 10000;
    let returnEvent;
    this.timelines.forEach(function(timelineInstance) {
      let eventNearMiddle = timelineInstance.findEventNearMiddle();
      if(eventNearMiddle && eventNearMiddle.distance < lastDistance) {
        lastDistance = eventNearMiddle.distance;
        returnEvent = eventNearMiddle;
      }
    });
    return returnEvent;
  }

  findEventByIndex(timelineId, eventIndex) {
    let tl = this.findTimelineById(timelineId) || null;
    let returnEvent = null;

    if (tl)
      returnEvent = tl.getEventByIndex(eventIndex);

    return returnEvent;
  }

  findTimelineByIndex(index) {
    return this.timelines[index];
  }

  findTimelineById(id) {
    let timeline;
    this.timelines.forEach((_timeline)=> {
      if(_timeline.id === id) {
        timeline = _timeline;
      }
    });
    return timeline;
  }

  getEventCollection() {
    let getEventsByTimeline = (id, events = [])=> {
      let timeline = this.findTimelineById(id);
      let TLEvents = timeline.events;
      TLEvents.forEach((_event)=> {
        if( ['game', 'split'].indexOf(_event.type) > -1 ) {
          events.push({
            id: _event.id,
            index: _event.index,
            selected: _event.selected,
            timelineSelected: _event.timelineName,
            timelineId: id
          });
          if( _event.timelineName !== undefined ) {
            events = getEventsByTimeline(_event.timelineName, events);
          }
        }
      });
      return events;
    }
    let id = this.findTimelineByIndex(0).id;
    let events = getEventsByTimeline(id);
    return events;
  }

  forEach(f) {
    if(typeof f === 'function') {
      this.timelines.forEach(f);
    }
  }
}

export default Timelinecollection;
