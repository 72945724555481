import _u from "wtc-utility-helpers";
import {
  default as ElementController,
  ExecuteControllers
} from "wtc-controller-element";

class Home extends ElementController {
  constructor(element) {
    super(element);

    $(document).on("click", ".modal__close", function(e) {
      nclood.Metrics.trackLink(
        {
          eVars: { 39: "button:close" },
          events: [53]
        },
        e
      );

      return false;
    });
  }
}

ExecuteControllers.registerController(Home, "Home");

export default Home;
