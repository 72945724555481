import {default as ElementController, ExecuteControllers}  from 'wtc-controller-element';

class Videos extends ElementController {
  constructor (element) {
    super(element);
   
    $(document).on('click', '.modal__close', function(e) {
      let op = this;
      nclood.Metrics.trackLink({
        eVars: { 39: 'button:close'},
        events: [53]
      }, e);

      return false;
    });
  }
}

ExecuteControllers.registerController(Videos, 'Videos');
export default Videos;
