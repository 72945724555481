import _u from "wtc-utility-helpers";

import { IDGenerator } from "./Importers/StaticData";

const degug = false;

let TimelineParser = function(wrapper) {
  if (degug) {
    console.log("---------------- INITIATING ------------------");
  }

  // the needle saves the current node and prev node so we can reverse back to the parent.
  // level is simply to indicate if we should query the first child or next sibling.
  let needle = {
    prev: null,
    level: 0,
    current: null
  };

  // if no wrapper is passed then we use the main and start at level 1
  if (!wrapper) {
    if (degug) {
      console.log("No wrapper passed, using default .timeline--main");
    }

    wrapper = document.querySelector(".wrapper--small");
    needle.current = wrapper.querySelector("#small-timeline-label");
    needle.level = 1;
  } else {
    needle.current = wrapper;
  }
  if (degug) {
    console.log("Wrapper: ", wrapper);
  }

  // the final variable returned
  let timeline = [];
  let ended = false;
  let next = null;
  if (degug) {
    console.log("----------------- WALKING 🚶🏼 -----------------");
  }

  // let's walk the timeline, shall we?
  while (!ended) {
    if (needle.level === 0) {
      next = needle.current.firstElementChild;
    } else {
      next = needle.current.nextElementSibling;
    }

    if (next) {
      if (degug) {
        console.log(`Level: ${needle.level}`);
        console.log("Next: ", next);
      }

      // if the node is a timeline
      if (_u.hasClass("timeline", next)) {
        if (degug) {
          console.log("Next Type: timeline");
        }

        let node = {
          type: "timeline",
          title: next.firstElementChild.innerHTML,
          games: []
        };

        // we get the games so we can assemble the title screen
        let games = next.querySelectorAll(".timeline__game");
        let border = next.querySelector(".timeline-border");
        for (let game of games) {
          if (game.parentNode == border || game.parentNode == next) {
            let nodeGame = {
              title: game.querySelector(".timeline__game-title").innerHTML,
              thumbnail: {
                src: game
                  .querySelector(".timeline__game-thumbnail")
                  .getAttribute("src"),
                srcset: game
                  .querySelector(".timeline__game-thumbnail")
                  .getAttribute("srcset")
              },
              href: game.getAttribute("data-href") || null,
              id: game.getAttribute("data-id")
            };

            node.games.push(nodeGame);
          }
        }

        timeline.push(node);

        if (degug) {
          console.log("New Node: ", node);
        }

        // next becomes the border and we keep the level at 0
        next = border;
        needle.level = 0;
      }
      // if the node is an era, easy, get the title an make sure we are level 1
      else if (_u.hasClass("timeline-era", next)) {
        if (degug) {
          console.log("Next Type: era");
        }
        let node = {
          type: "era",
          title: next.innerHTML
        };

        timeline.push(node);

        if (degug) {
          console.log("New Node: ", node);
        }

        if (needle.level === 0) {
          needle.level++;
        }
      }
      // same as the era but pass an array with the events
      else if (_u.hasClass("timeline-events", next)) {
        if (degug) {
          console.log("Next Type: events");
        }

        let node = {
          type: "events",
          events: []
        };

        let events = next.querySelectorAll(".timeline-event");
        if (events.length > 0) {
          for (let event of events) {
            node.events.push(event.innerHTML);
          }
        }

        timeline.push(node);

        if (degug) {
          console.log("New Node: ", node);
        }

        if (needle.level === 0) {
          needle.level++;
        }
      }
      // pladeholders are mainly used for the JS timeline
      else if (_u.hasClass("timeline-placeholder", next)) {
        if (degug) {
          console.log("Next Type: placeholder");
        }

        let node = {
          type: "placeholder"
        };

        timeline.push(node);

        if (degug) {
          console.log("New Node: ", node);
        }

        if (needle.level === 0) {
          needle.level++;
        }
      }
      // if it's a game we get the thumbnail and logo and events
      // we dont change the level here because games can be outside the border
      else if (_u.hasClass("timeline__game", next)) {
        if (degug) {
          console.log("Next Type: game");
        }

        let title = next.querySelector(".timeline__game-title").innerHTML;

        let node = {
          type: "game",
          title: title.replace(/(<span\b[^>]*>)[^<>]*(<\/span>)/gi, ""),
          fullTitle: title.replace(/<\/?span\b[^>]*>/gi, ""),
          description: next.querySelector(".timeline__game-description")
            .innerHTML,
          thumbnail: {
            src: next
              .querySelector(".timeline__game-thumbnail")
              .getAttribute("src"),
            srcset: next
              .querySelector(".timeline__game-thumbnail")
              .getAttribute("srcset")
          },
          logo: {
            src: next.querySelector(".timeline__game-logo").getAttribute("src"),
            srcset: next
              .querySelector(".timeline__game-logo")
              .getAttribute("srcset")
          },
          href: next.getAttribute("data-href") || null,
          id: next.getAttribute("data-id"),
          events: []
        };

        let events = next.querySelectorAll(".timeline-event");
        if (events.length > 0) {
          for (let event of events) {
            node.events.push(event.innerHTML);
          }
        }

        timeline.push(node);

        if (degug) {
          console.log("New Node: ", node);
        }
      }
      // splits a bit more tricky
      else if (_u.hasClass("split", next)) {
        if (degug) {
          console.log("Next Type: split");
        }

        // we first get the title and description to assemble the screen
        let node = {
          type: "split",
          title: next.querySelector(".split-title").innerHTML,
          description: next.querySelector(".split-description").innerHTML,
          options: []
        };

        // we then get the options labels which point to their input toggles
        let options = next
          .querySelector(".split__option-title-wrapper")
          .querySelectorAll(".split__option-title");
        for (let i = 1; i <= options.length; i++) {
          const option = options[i - 1];
          // get the input toggle
          let optionId = option.getAttribute("for");

          let optionNode = {
            title: option.innerHTML,
            id: IDGenerator(),
            timeline: [],
            optionNumber: `option${i}`
          };

          // the option wrapper is right after it
          let optionWrapper = document.getElementById(optionId)
            .nextElementSibling;
          if (degug) {
            console.log(`Split id: ${optionId}`);
          }
          if (degug) {
            console.log("Split wrapper: ", optionWrapper);
          }

          // recursive beauty
          // we assemble the options timeline by passing the option wrapper
          optionNode.timeline = TimelineParser(optionWrapper);

          node.options.push(optionNode);
        }

        timeline.push(node);

        needle.level = 1;
      }

      if (degug) {
        console.log("------------------------------------");
      }
    } else {
      // ok, no next, now what Jimmy?
      if (degug) {
        console.log("NO NEXT 😓");
      }
      // if we are not at the top level we move the needle back up
      if (needle.level > 0) {
        if (degug) {
          console.log(`We are still at level: ${needle.level}\nMoving up...`);
        }

        // get the parent from the prev node which
        let get = true;
        let parent = needle.prev.parentNode;
        // if the parent is not the wrapper it means we are still inside the timeline
        if (parent != wrapper) {
          if (degug) {
            console.log("Parent: ", parent);
          }
          // we keep going up until we hit one the nodes we want
          while (get) {
            if (_u.hasClass("timeline-border split__option", parent)) {
              if (degug) {
                console.log("Parent: timeline-border split__option");
              }
              next = parent;
              needle.level = 1;
              get = false;
            } else if (_u.hasClass("timeline--main", parent)) {
              if (degug) {
                console.log("Parent: timeline--main");
              }
              get = false;
              ended = true;
            } else {
              parent = parent.parentNode;
            }
          }
          if (degug) {
            console.log("------------------------------------");
          }
        } else {
          if (degug) {
            console.log("Oops, parent is the wrapper. We are good.");
          }
          ended = true;
        }
      }

      // no next, no parent?
      // f$^&#ing done!
      if (degug && ended) {
        console.log("------------------ FINISHED 😎 ------------------");
      }
    }

    // save the nodes on the needle and keep on going
    needle.prev = needle.current;
    needle.current = next;
  }

  // return the beast
  return timeline;
};

export default TimelineParser;
