import _ from 'underscore';
import DisplayObject from '../DisplayObject/DisplayObject';

let _required = ['id'];

class Record {
  static isRequired(variableName) {
    if(required[variableName]) {
      return true;
    }
    return false;
  }

  constructor(data, required = _required) {
    required = _.unique(required.concat(_required));
    let requirementsMet = false;

    for(let attr in data) {
      let requiredIndex = required.indexOf(attr);
      if( requiredIndex > -1 ) {
        required.splice(requiredIndex, 1);
        if( required.length == 0 ) {
          requirementsMet = true;
        }
      }

      this[attr] = data[attr];
    }

    if( requirementsMet !== true ) {
      throw new Error('Requirements not met: ' + required.join(', '));
    }
  }

  get index() {
    return this._index || 0;
  }
  set index(index) {
    if(index > -1) {
      this._index = index;
    }
  }

  get id() {
    return this._id;
  }
  set id(id) {
    if(typeof id != 'string') {
      throw new Error('ID must be of type String');
    }
    this._id = id;
  }

  get relation() {
    return this._relation || null;
  }
  set relation(relatedRecord) {
    this._relation = relatedRecord;
    if( relatedRecord.relation === null ) {
      relatedRecord.relation = this;
    }
  }

  set displayObject(displayObject) {
    if( this._displayObject instanceof DisplayObject ) {
      this._displayObject.destroy();
    }
    if( displayObject instanceof DisplayObject ) {
      this._displayObject = displayObject;
    }
  }
  get displayObject() {
    return this._displayObject;
  }
}

export default Record;
