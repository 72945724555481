import {
  default as ElementController,
  ExecuteControllers,
} from "wtc-controller-element";

import NewsArticle from "../Controllers/NewsArticle";

const CARDS_PER_PAGE = 9;
const CARD_HIDDEN_CLASSNAME = "news-card--hidden";
const BUTTON_HIDDEN_CLASSNAME = "load-more--hidden";

class News extends ElementController {
  constructor(element) {
    super(element);

    new NewsArticle();

    // store relevant DOM elements
    this.loadMoreButton = element.querySelector(".load-more");
    this.newsCards;
    this.ariaLiveRegion = element.querySelector(".load-more-announce");

    // bind listeners to instance
    this.loadMore = this.loadMore.bind(this);
    this.onArticlesLoaded = this.onArticlesLoaded.bind(this);

    // add event listeners
    this.loadMoreButton.addEventListener("click", this.loadMore);
    window.addEventListener("articlesrendered", this.onArticlesLoaded);

    // article "load more" pagination
    this.page = 0;

    // flag to deal with hiding load more, etc.
    this.hasMoreNews = true;
  }

  incrementPagination() {
    this.page += 1;
  }

  revealNextGroupOfCards() {
    const visibleCards = this.newsCards.slice(0, this.page * CARDS_PER_PAGE);
    const numExistingCards = this.newsCards.filter(
      (card) => !card.classList.contains(CARD_HIDDEN_CLASSNAME)
    ).length;

    // update the aria live region with the number of new items added
    if (this.page > 1) {
      const numNewCards = visibleCards.length - numExistingCards;
      this.ariaLiveRegion.innerHTML = numNewCards + " new articles loaded.";
    }

    // remove the "hidden" classnames on new items
    visibleCards.forEach((card) =>
      card.classList.remove(CARD_HIDDEN_CLASSNAME)
    );

    // shift user focus to the first new card revealed
    if (this.page > 1) {
      this.focusNewCard(visibleCards[numExistingCards]);
    }
  }

  focusNewCard(card) {
    card.querySelector("a").focus();
  }

  updateHasMoreNews() {
    if (this.page * CARDS_PER_PAGE >= this.newsCards.length)
      this.hasMoreNews = false;
  }

  loadMore(e) {
    if (!this.hasMoreNews) return;

    this.incrementPagination();
    this.revealNextGroupOfCards();
    this.updateHasMoreNews();
    this.toggleLoadMoreVisibility(this.hasMoreNews);
  }

  toggleLoadMoreVisibility(visible) {
    this.loadMoreButton.classList[visible ? "remove" : "add"](
      BUTTON_HIDDEN_CLASSNAME
    );
  }

  onArticlesLoaded() {
    this.newsCards = Array.from(
      this.element.querySelectorAll("[data-controller='NewsArticles'] > *")
    );

    // hide all cards to start
    this.newsCards.forEach((card) => card.classList.add(CARD_HIDDEN_CLASSNAME));

    // show our load more button if necessary
    this.updateHasMoreNews();

    // kick off the rendering of news cards
    this.loadMore();
  }
}

ExecuteControllers.registerController(News, "News");
export default News;
