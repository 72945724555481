import { all } from 'pixi.js';

const styleEventText = new PIXI.TextStyle({
    align: 'center',
    padding: 5,
    fontFamily: 'Bitter',
    fontSize: 14,
    fontStyle: 'italic',
    fill: '#B99F65', // gradient
});
const styleEventTitle = new PIXI.TextStyle({
    align: 'center',
    fontFamily: 'Bitter',
    fontSize: 18,
    fill: '#FFFFFF', // gradient
});
const styleEventButton = new PIXI.TextStyle({
    align: 'center',
    fontFamily: 'Bitter',
    fontSize: 18,
    fill: '#B99F65', // gradient
});
const styleLabel = new PIXI.TextStyle({
    align: 'center',
    fontFamily: 'Bitter',
    fontSize: 18,
    fill: '#000000', // gradient
});
const styleEra = new PIXI.TextStyle({
    padding: 5,
    align: 'left',
    fontFamily: 'Bitter',
    fontSize: 14,
    fontStyle: 'italic',
    fill: '#9b9b9b', // gradient
});

class DisplayObject extends PIXI.Container {

  emitEvent(eventname, data) {
    var event = new CustomEvent(eventname, {detail: data});

    window.dispatchEvent(event);
  }

  get styleEventText() {
    return styleEventText;
  }
  get styleEventTitle() {
    return styleEventTitle;
  }
  get styleEventButton() {
    return styleEventButton;
  }
  get styleEra() {
    return styleEra;
  }
  get styleLabel() {
    return styleLabel;
  }
}

export default DisplayObject;
