import _u from 'wtc-utility-helpers';
import { rmGetState } from './ReduceMotion';

import {default as ElementController, ExecuteControllers}  from 'wtc-controller-element';

class VideoHelper extends ElementController {
  constructor(element) {
    super(element);

    this.isResizing = false;
    this.overlay = element.querySelector('.home-hero__overlay');
    this.video =  element.querySelector('.home-hero__video');
    this.videoText = element.querySelector('.hero__video-text');

    this.videoTextShown = false;
    this.finalFrameShown = false;

    this.duration = 0;
    this.percentage_complete = 0;

    this.video.addEventListener('timeupdate', this.timeUpdateHandler.bind(this),false);

    window.addEventListener('resize',function(){
      let el = this.element;
      _u.addClass('is-resizing',el);
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(function(){
        _u.removeClass('is-resizing',el);
      },50);
    }.bind(this));

    if (rmGetState()) {
      this.noAutoPlay();
    }

    if(this.video.paused) {
      let testVideo = this.video;
      let playPromise = testVideo.play();
      if((playPromise !== undefined)) {
        playPromise.then(function(){
          // pause, reset, show, and play video from beginning
        }.bind(this)).catch(function(error){
          // add a no-autoplay class to html, start intro sequence from the last frame.
          if(error.name === 'NotAllowedError') {
            this.noAutoPlay();
          }
        }.bind(this));
      }
    }
  }

  noAutoPlay() {
    document.querySelector('html').classList.add('no-autoplay');
    this.video.style.display = 'none';
    // this.scenes[3].play(true);
    _u.removeClass('fade-out', this.overlay);
    this.finalFrameShown = true;
  }

  timeUpdateHandler() {
    if(this.duration === 0) {
      this.duration = this.video.duration;
    }

    if (rmGetState()) {
      this.noAutoPlay();
      return;
    }

    this.percentage_complete = (this.video.currentTime/this.duration) * 100;
    if(this.percentage_complete > 35 && this.percentage_complete < 50) {
      if(!this.videoTextShown) {
        _u.removeClass('fade-out',this.videoText);
        this.videoTextShown = true;
      }
    } else {
      if(this.videoTextShown) {
        _u.addClass('fade-out',this.videoText);
        this.videoTextShown = false;
      }
    }

    if (this.percentage_complete > 75) {
      if(!this.finalFrameShown) {
        _u.removeClass('fade-out', this.overlay);
        this.finalFrameShown = true;
      }
    }
  }
}

ExecuteControllers.registerController(VideoHelper, 'VideoHelper');
export default VideoHelper;
