import { all } from "pixi.js";
import TimelineModel from "./Models/Timeline";
import timelineCollection from "./Importers/StaticData";
import TimelineDisplay from "./DisplayObject/Timeline";
import EventDisplay from "./DisplayObject/Event";
import EraDisplay from "./DisplayObject/Era";
import Vector from "../../../../node_modules/wtc-vector/dist/es5-bundle"
import { TweenMax, Power2 } from "gsap";
import _u from "wtc-utility-helpers";
import Breakpoints from "wtc-utility-breakpoint";

/**
 * Timeline is the main controller for the timeline application layer
 *
 * @static
 * @class Timeline
 * @author Liam Egan <liam@wethecollective.com>
 * @version 0.5
 * @created March 16, 2017
 */
class Timeline {
  /**
   * TimelineDragStart event. This is triggered when the user starts dragging the timeline.
   *
   * @event Timeline#TimelineDragStart
   * @type {object}
   * @property {Vector} dragOffset - The offset identifying the user's mouse position.
   */
  /**
   * TimelineFoundEvent event. Triggered when a new event is found within range of the center (150px);
   *
   * @event Timeline#TimelineFoundEvent
   * @type {object}
   * @property {Event} eventObj - The found Event display object.
   */
  /**
   * TimelineDragEnd event. Triggered when timeline dragging stops.
   *
   * @event Timeline#TimelineDragEnd
   * @type {object}
   * @property {Number} xPosition - The ending x position of the timeline.
   * @property {Number} velocity - The velocity of the 'throw' of the timeline.
   */
  /**
   * TimelineDrag event. Triggered when timeline is dragged.
   *
   * @event Timeline#TimelineDrag
   * @type {object}
   * @property {Number} xPosition - The ending x position of the timeline.
   * @property {Number} velocity - The velocity of the 'throw' of the timeline.
   */
  /**
   * DirectionMove event. Triggered when the user hits up or down.
   *
   * @event Timeline#DirectionMove
   * @type {object}
   * @property {Number} direction - The direction the user has moved.
   */

  /**
   * @static init - Initialises the timeline application
   *
   * @public
   */
  static init() {
    PIXI.settings.RESOLUTION = 2;
    this.renderer = new PIXI.CanvasRenderer(window.innerWidth, 240, {
      // antialias: true,
      transparent: true,
      autoResize: true
    });
    this.stage = new PIXI.Container();
    this.masker = new PIXI.Container();
    this.maskContainer = new PIXI.Container();
    this.container = new PIXI.Container();
    this.masker.addChild(this.container);
    // this.masker.addChild(this.mask);
    this.stage.addChild(this.masker);

    // add the masks
    this.maskElements = {};
    this.maskElements.mask1 = new PIXI.Sprite(
      PIXI.loader.resources.gradient.texture
    );
    this.maskElements.mask2 = new PIXI.Sprite(
      PIXI.loader.resources.gradient.texture
    );
    this.maskElements.background = new PIXI.Graphics();
    this.maskElements.background.beginFill(0xffffff);
    this.maskElements.background.drawRect(0, 0, 1000, 1000);

    this.maskContainer.addChild(this.maskElements.background);
    this.maskContainer.addChild(this.maskElements.mask1);
    this.maskContainer.addChild(this.maskElements.mask2);

    this.resize();
    window.addEventListener("resize", this.resize.bind(this));

    this.domWrapper = document.querySelector(".wrapper--big .timeline-wrapper");
    this.domWrapper
      .querySelector(".timeline-wrapper-canvas")
      .appendChild(this.renderer.view);

    let data = timelineCollection();
    this.timelineCollection = data.collection;
    this.screensArray = data.screens;
    this.pointerDown = false;
    this.dragging = false;
    this.cancelAnimation = false;
    this.tweens = [];

    let timelineDisplay = this.createTimeline(
      this.timelineCollection.findTimelineByIndex(0)
    );
    this.container.addChild(timelineDisplay);
    this.initialiseEvents();
    this.render();

    this.setupNav();
  }

  /**
   * moveByEvent - centers and selectes the passed event
   *
   * @static
   * @param {EventModel} event
   *
   * @memberOf Timeline
   */
  static moveByEvent(forwards = true) {
    let eventCollection = this.timelineCollection.getEventCollection();
    let index = this.getCurrentNavIndex();
    let newEvent = null;

    if (index !== null) {
      index = forwards ? index + 1 : index - 1;

      if (
        (index < eventCollection.length && forwards) ||
        (index >= 0 && !forwards)
      ) {
        newEvent = this.timelineCollection.findEventByIndex(
          eventCollection[index].timelineId,
          eventCollection[index].index
        );
      }
    } else if (forwards) {
      newEvent = this.timelineCollection.findEventByIndex(
        eventCollection[0].timelineId,
        eventCollection[0].index
      );
    }

    if (newEvent) {
      this.navHistory.currentId = newEvent.id;
      // this.checkNavPos(index);

      newEvent.displayObject.selected = true;
      this.emitEvent("EventSelected", { event: newEvent.displayObject });
    }
  }

  static getCurrentNavIndex() {
    if (this.navHistory.currentId) {
      let eventCollection = this.timelineCollection.getEventCollection();

      for (let i = 0; i < eventCollection.length; i++) {
        if (eventCollection[i].id == this.navHistory.currentId) {
          return i;
        }
      }
    } else {
      return null;
    }
  }

  static checkNavPos(index = null) {
    if (!index) {
      index = this.getCurrentNavIndex();
    }

    if (index !== null) {
      let eventCollection = this.timelineCollection.getEventCollection();

      if (index + 1 >= eventCollection.length) {
        this.nextBtn.setAttribute("disabled", "true");
      } else {
        this.nextBtn.removeAttribute("disabled");
      }

      if (index - 1 < 0) {
        this.prevBtn.setAttribute("disabled", "true");
      } else {
        this.prevBtn.removeAttribute("disabled");
      }
    }
  }

  /**
   * next - moves the timeline to the next game or split on the current timeline
   *
   * @static
   *
   * @memberOf Timeline
   */
  static next() {
    this.moveByEvent();

    nclood.Metrics.trackLink({
      eVars: { 39: "button:next" },
      events: [53]
    });
  }

  /**
   * prev - moves the timeline to the prev game or split on the current timeline
   *
   * @static
   *
   * @memberOf Timeline
   */
  static prev() {
    this.moveByEvent(false);

    nclood.Metrics.trackLink({
      eVars: { 39: "button:previous" },
      events: [53]
    });
  }

  /**
   * setupNav - sets up the buttons next/prev to navigate on the timeline
   *
   * @static
   *
   * @memberOf Timeline
   */
  static setupNav() {
    this.navHistory = {
      currentId: null,
      currentEvent: null
    };
    this.nextBtn = document.createElement("button");
    this.nextBtn.setAttribute("class", "timeline-nav timeline-nav--next");
    this.nextBtn.innerHTML = '<span class="visually-hidden">Next</span>';
    this.nextBtn.addEventListener("click", this.next.bind(this));

    this.prevBtn = document.createElement("button");
    this.prevBtn.setAttribute("class", "timeline-nav timeline-nav--prev");
    this.prevBtn.setAttribute("disabled", "true");
    this.prevBtn.innerHTML = '<span class="visually-hidden">Previous</span>';
    this.prevBtn.addEventListener("click", this.prev.bind(this));

    this.domWrapper.appendChild(this.prevBtn);
    this.domWrapper.appendChild(this.nextBtn);
  }

  /**
   * @static initialiseEvents - This initialises the timeline events, such as drag-and-drop on the container and various other custom listeners
   *
   * @public
   */
  static initialiseEvents() {
    this.container.interactive = true;
    this.redrawHitArea();

    if (Breakpoints.current > 1) {
      this.container
        .on("pointerdown", this.onDragStart.bind(this))
        .on("pointerup", this.onDragEnd.bind(this))
        .on("pointerupoutside", this.onDragEnd.bind(this))
        .on("pointermove", this.onDragMove.bind(this));
    }

    window.addEventListener("EventSelected", this.onEventSelected.bind(this)); // For binding animation of the timeline in response to an event being selected
    window.addEventListener(
      "TimelineSelected",
      this.onTimelineSelected.bind(this)
    );

    window.addEventListener("keydown", this.onKeyDown.bind(this));
  }

  /**
   * @static redrawHitArea - Redraws the hit area for the container to allow for an expanding or contracting field
   *
   * @return {undefined}
   */
  static redrawHitArea() {
    this.container.hitArea = new PIXI.Rectangle(
      0,
      -this.container.height,
      this.container.width,
      this.container.height * 2
    );
  }

  /**
   * Private methods
   */

  /**
   * @static emitEvent - Takes a custom event name and data and emits it to the window.
   *
   * @private
   * @param  {String} eventname The name of the event to emit
   * @param  {Object} data      The data to emit to event.detail
   * @return {undefined}
   */
  static emitEvent(eventname, data) {
    if (typeof eventname != "string") {
      return;
    }

    var event = null;

    event = new CustomEvent(eventname, { detail: data });

    window.dispatchEvent(event);
  }

  /**
   * Public methods
   */

  /**
   * @static resize - Resize the window
   *
   * @return {undefined}
   */
  static resize() {
    this.oldDimensions = this.dimensions;
    this.dimensions = new Vector(window.innerWidth, 240);

    if (this.oldDimensions) {
      let oldC = this.oldDimensions.x / 2;
      let oldPosC = oldC - this.container.x;
      let newC = this.dimensions.x / 2;

      this.container.x = newC - oldPosC;
      this.container.y = this.dimensions.height / 2;
    } else {
      this.container.x = this.dimensions.width / 2;
      this.container.y = this.dimensions.height / 2;
    }

    let mask1 = this.maskElements.mask1;
    let mask2 = this.maskElements.mask2;
    let bg = this.maskElements.background;
    mask1.height = mask2.height = window.innerHeight;
    mask1.position.x = window.innerWidth - mask1.width;
    mask2.scale.x = -1;
    mask2.position.x = mask2.width;
    bg.width = window.innerWidth;
    bg.height = window.innerHeight;

    var texture = PIXI.RenderTexture.create(
      window.innerWidth,
      window.innerHeight
    );
    this.renderer.render(this.maskContainer, texture);
    if (this.mask) this.mask.parent.removeChild(this.mask);
    this.mask = new PIXI.Sprite(texture);
    this.masker.addChild(this.mask);
    this.container.mask = this.mask;

    window.mask1 = mask1;
    window.mask2 = mask2;
    this.renderer.resize(this.dimensions.x, this.dimensions.y);
  }

  /**
   * @static createTimeline - creates a timeline based on a timeline model.
   *
   * @param  {Model.Timeline} timelineModel The timeline model to render
   * @return {Display.Timeline}             The Timeline display object to be added
   */
  static createTimeline(timelineModel) {
    if (timelineModel instanceof TimelineModel) {
      let timelineDisplay = new TimelineDisplay(timelineModel);
      timelineModel.displayObject = timelineDisplay;
      timelineModel.events.forEach(eventData => {
        timelineDisplay.addEvent(eventData);
      });
      return timelineDisplay;
    }
  }

  /**
   * @static render - Render the timeline
   *
   * @return {type}  description
   */
  static render() {
    this.renderer.render(this.stage);

    if (this.rendering) {
      requestAnimationFrame(this.render.bind(this));
    }
  }

  /**
   * Event listeners
   */

  /**
   * @static onTimelineSelected - Listens to TimelineSelected events and creates secondary timelines accordingly.
   *
   * @param  {object} e event object
   * @return {undefined}
   */
  static onTimelineSelected(e) {
    let timelineID = e.detail.timelineName;
    let timelineModel = this.timelineCollection.findTimelineById(timelineID);

    // let n = this.timelineCollection.findEventByIndex(timelineID, 0);
    // this.navHistory.currentId = timelineModel.id;

    let relatedTo = this.timelineCollection.findTimelineById(
      timelineModel.relatedTo
    );
    if (relatedTo) {
      let secondaryTimeline = this.createTimeline(timelineModel);
      relatedTo.secondaryTimeline = secondaryTimeline;
    }

    setTimeout(() => {
      this.redrawHitArea(); // redraw the timeline hit area
      if (timelineModel.events.length == 1) {
        this.moveByEvent();
      } else {
        this.checkNavPos();
      }
    }, 200);
  }

  /**
   * @static onEventSelected - Listens to EventSelected events and moves the timeline to display the selected event in the middle of the screen
   *
   * @param  {Object} e The event object
   * @return {undefined}
   */
  static onEventSelected(e) {
    let ev = e.detail.event;
    let position = ev.absCentre;
    let endPosition =
      window.innerWidth / 2 - position.x + this.container.position.x; // @todo replace this with an actual reference to the timeline to which this event belongs
    let distance = Math.abs(this.container.position.x - endPosition);
    let time = distance / 100;
    if (time > 1) time = 1;
    this.cancelAnimation = true; // cancels any already runnin animation

    let animationObject = { pos: this.container.position.x };

    this.navHistory.currentId = ev.data.id;

    this.checkNavPos();

    this.tweens.push(
      TweenMax.to(animationObject, time, {
        pos: endPosition,
        ease: Power2.easeInOut,
        delay: 0.05,
        onUpdate: () => {
          this.container.position.x = animationObject.pos;
        }
      })
    );
  }

  /**
   * @static onDragStart - Listens to PointerDown and is responsible for setting up the test for, and ultimately the response to, a drag start.
   *
   * @param  {Object} e The event object
   * @return {undefined}
   */
  static onDragStart(e) {
    this.pointerDown = true; // pointer is down
    this.dragTesting = true; // testing for drag
    let originalEvent =
      window.TouchEvent && e.data.originalEvent instanceof TouchEvent
        ? e.data.originalEvent.changedTouches[0]
        : e.data.originalEvent;
    this.clickPositionX = originalEvent.clientX;

    this.draggingOffset = new Vector(
      originalEvent.clientX - this.container.position.x,
      originalEvent.clientY - this.container.position.y
    );
    this.dragData = e.data;
  }

  /**
   * @static onDragEnd - Listens to PointerUp and PointerUpOutside and is responsible for ending the drag functionality
   *
   * @param  {Object} e The event object
   * @return {undefined}
   */
  static onDragEnd(e) {
    this.pointerDown = false; // pointer is lo longer down
    this.dragTesting = false;
    this.dragData = null;

    if (this.dragging) {
      this.dragging = false;

      // This takes the inertia of the object and animates it down.
      let animateInertia = () => {
        let newPos = this.container.position.x + this.velocity;
        let middle = window.innerWidth / 2;

        if (newPos > middle) {
          this.velocity *= -1;
          this.container.position.x = middle;
        } else if (newPos < middle - this.container.width) {
          this.velocity *= -1;
          this.container.position.x = middle - this.container.width;
        } else {
          this.container.position.x += this.velocity;
          this.velocity *= 0.9;
        }

        if (
          Math.abs(this.velocity) > 0.1 &&
          !this.dragging &&
          !this.cancelAnimation
        ) {
          requestAnimationFrame(animateInertia);
        } else {
          // find the event near the middle and, if it's less than 150px away,
          let eventNearMiddle = this.timelineCollection.findEventNearMiddle();
          if (eventNearMiddle.distance < 150) {
            this.emitEvent("TimelineFoundEvent", { eventObj: eventNearMiddle });
          }
        }
      };
      animateInertia();

      this.emitEvent("TimelineDragEnd", {
        xPosition: this.container.position.x,
        velocity: this.velocity
      });
    }
  }
  /**
   * @static onDragMove - Responds to onPointerMove if dragging is happening. Responsible for moving the timeline in response to a drag
   *
   * @param  {Object} e The event object
   * @return {undefined}
   */
  static onDragMove(e) {
    if (this.dragTesting) {
      let newPosition = this.dragData.getLocalPosition(this.stage);
      let originalEvent =
        window.TouchEvent && e.data.originalEvent instanceof TouchEvent
          ? e.data.originalEvent.changedTouches[0]
          : e.data.originalEvent;
      let movedX = Math.abs(this.clickPositionX - originalEvent.clientX);

      // I fthe mouse has moved more than 2 px since it's been pressed, then start the drag
      if (movedX > 2) {
        // determine the dragging offet
        // Set this to drag
        this.dragging = true;
        this.dragTesting = false;

        // Cancel any currently running animation
        this.cancelAnimation = true;

        // Emit the event;
        this.emitEvent("TimelineDragStart", {
          dragOffset: this.draggingOffset
        });
      }
    }

    if (this.dragging) {
      let newPosition = this.dragData.getLocalPosition(this.stage);
      let posX = newPosition.x - this.draggingOffset.x;
      let middle = window.innerWidth / 2;
      if (posX > middle || posX < middle - this.container.width) {
        return;
      }
      this.container.position.x = posX;
      this.velocity = this.oldPosition ? newPosition.x - this.oldPosition.x : 0;
      if (Math.abs(this.velocity) === 1) this.velocity = 0; // This is just a hack to account for a velocity of 1 when no mouse movement
      this.oldPosition = newPosition;

      this.emitEvent("TimelineDrag", {
        xPosition: this.container.position.x,
        velocity: this.velocity
      });
    }
  }

  static onKeyDown(e) {
    switch (e.keyCode) {
      // left
      case 37:
        e.preventDefault();
        this.prev();
        break;
      // up
      case 38:
        this.emitEvent("DirectionMove", { direction: "up" });
        break;
      // right
      case 39:
        e.preventDefault();
        this.next();
        break;
      // down
      case 40:
        this.emitEvent("DirectionMove", { direction: "down" });
        break;
      default:
        return;
    }
  }

  /**
   * Getters and Setters
   */

  static set secondaryTimelineDisplay(timeline) {
    if (this.secondaryTimelineDisplay) {
      this.secondaryTimelineDisplay.parent.removeChild(
        this.secondaryTimelineDisplay
      );
    }
    if (timeline instanceof timelineDisplay) {
      this._secondaryTimelineDisplay = timeline;
    }
  }
  static get secondaryTimelineDisplay() {
    return this._secondaryTimelineDisplay;
  }

  static set cancelAnimation(value) {
    if (value === true) {
      this._cancelAnimation = true;
      let i = 1;
      let cancelAnimationCancel = () => {
        if (i <= 0) {
          this.cancelAnimation = false;
        } else {
          requestAnimationFrame(cancelAnimationCancel);
        }
        i--;
      };
      cancelAnimationCancel();
      this.tweens.forEach(tween => {
        if (tween && tween.kill) tween.kill();
      });
      this.tweens = [];
    } else {
      this._cancelAnimation = false;
    }
  }
  static get cancelAnimation() {
    return this._cancelAnimation;
  }

  static set rendering(value) {
    this._rendering = value === true;
  }
  static get rendering() {
    return this._rendering !== false;
  }

  static set dragging(value) {
    this._dragging = value === true;
  }
  static get dragging() {
    return this._dragging === true;
  }

  static get screens() {
    return this.screensArray;
  }
  static get collection() {
    return this.timelineCollection;
  }
}

export default Timeline;
