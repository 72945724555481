import _u from "wtc-utility-helpers";
import { Viewport } from 'wtc-controller-viewports';
import { ExecuteControllers }  from "wtc-controller-element";
import { rmGetState } from "./ReduceMotion";


class Triforce extends Viewport {
  constructor(element) {
    super(element);
    let op = this;

    this.$element = $(element);
    this._rotate = true;
    this._rotateInterval = 4000;
    this._current = null;
    this._touchstartX = 0;
    this._touchstartY = 0;
    this._touchendX = 0;
    this._touchendY = 0;

    if (_u.getViewportDimensions().width < 865 || rmGetState()) {
      this._rotate = false;
    }

    this.symbolClick();

    this.mobileNav();
    // this.swipeListener();

    this._current = this.$element.attr("data-triforce");

    if (!rmGetState())
      this.autoRotate();
    else
      this._cycle('next');

    window.addEventListener("resize", function(e) { op.onResize(e); });
    window.addEventListener("reduce-motion", ({ detail }) => {
      if (this._rotate && detail.state) this._rotate = false;
    });
  }

  symbolClick() {
    var op = this;

    var $triforceContainer = this.$element;

    $(".triforce-symbol button", this.$element).on("click", function() {
      op.swap($(this));

      if (op._rotate) op._rotate = false;

      return false;
    });
  }

  swap(nextEl) {
    var current = $('.triforce-symbol button[data-triforce="'+this._current+'"]');
    current.removeAttr('disabled');

    nextEl.attr('disabled', 'true');

    this._current = nextEl.attr("data-triforce");
    this.$element.attr('data-triforce', this._current);
  }

	_cycle(direction) {
    var op = this;
    var next;
    var current = $('.triforce-symbol button[data-triforce="'+op._current+'"]');

    switch(direction) {
      case "next":
        next = current.next();
        if (!next[0]) next = $(".triforce-symbol button").first()
        break;

      case "previous":
        next = current.prev();
        if (!next[0]) next = $('.triforce-symbol button').last()
        break;
    }

    op.swap(next);
  }

	autoRotate() {
    var op = this;

    if (op._rotate === true) {
      setTimeout(function () {
        if (op._rotate === true) {
          op._cycle('next');
          op.autoRotate();
        }
      }, op._rotateInterval);
    }
  }

	mobileNav() {
    var op = this;

    $(document).on('click', '.triforce--mobile-nav-prev, .triforce--mobile-nav-next', function() {
      console.log(1)
      op._cycle($(this).attr('data-direction'));
      if (op._rotate) { op._rotate = false; }
      return false;
    });

    $(document).on('swipe', '.glossary--detail-content', function(e) {
      return false;
    });
  }

	swipeListener() {
    var op = this;
    var detail = document.querySelector('.triforce--detail');

    detail.addEventListener('touchstart', function(event) {
        op._touchstartX = event.changedTouches[0].screenX;
        op._touchstartY = event.changedTouches[0].screenY;
    }, false);

    detail.addEventListener('touchend', function(event) {
        op._touchendX = event.changedTouches[0].screenX;
        op._touchendY = event.changedTouches[0].screenY;
        op.swipeGesture();
    }, false);
  }

	swipeGesture() {
    var op = this;
    var next;

    // omit vertical swipes
    if (parseInt(op._touchstartY - op._touchendY) < 35 && parseInt(op._touchstartY - op._touchendY) > -35) {
      // Next
      if (this._touchendX < this._touchstartX) {
        next = 'next';
        }

        // Prev
        if (this._touchendX > this._touchstartX) {
            next = 'previous';
        }

        if (next != undefined && next.length > 0)
        {
          if (op._rotate) { op._rotate = false; }
          op._cycle(next);
        }
    }
  }

  onResize(evt) {
    var op = this;

    if (_u.getViewportDimensions().width < 865) {
      op._rotate = false;
    } else if (op._rotate === false) {
      op._rotate = true;
      op.autoRotate();
    }
  }
}

ExecuteControllers.registerController(Triforce, 'Triforce');

export default Triforce;
