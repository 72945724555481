import _u from "wtc-utility-helpers";
import {
  default as ElementController,
  ExecuteControllers,
} from "wtc-controller-element";
import CONFIG from "../config";

const dateFormat = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
};

const query = `#graphql
query NewsArticlesForZeldaPortal(
	$locale: Locale!
	$skip: Int!
	$where: NewsArticleFilter
	$limit: Int!
) {
	collection: newsArticles(
		locale: $locale
		skip: $skip
		limit: $limit
		where: $where
		sort: [publishDate_DESC]
	) {
		total
		items {
			id
			locale
			title
			publishDate
			contentRating {
				code
				label
			}
			contentDescriptors {
				label
				type
			}
			media {
				url(transforms: [{
					resize: { width: "318" }
					dpr: "auto"
				}])
			}
			body {
				text(characterLimit: 88)
			}
			slug
		}
	}
}
`;

class NewsArticles extends ElementController {
  constructor(element) {
    super(element);

    var newsPage = document.getElementById("page-news");
    var articleLimit;
    if (newsPage === null) {
      articleLimit = 3;
    } else {
      articleLimit = 100;
    }

    this.variables = {
      locale: document.documentElement.lang.replace("-", "_"),
      limit: Number(articleLimit),
      skip: Number(element.dataset.skip || "0"),
      where: {
        tags: { all: ["syndicationZeldaPortal"] },
      },
    };

    this.render();
  }

  async render() {
    try {
      const { items } = await this.fetchArticles();
      const articleElems = items.map(this.createArticleElem.bind(this));
      while (this.element.firstChild) this.element.firstChild.remove();
      for (const elem of articleElems) {
        this.element.appendChild(elem);
      }
      window.dispatchEvent(new Event("articlesrendered"));
    } catch (err) {
      console.warn(err);
    }
  }
  async fetchArticles() {
    const url = new URL("https://graph.nintendo.com/");
    url.searchParams.set("query", query);
    url.searchParams.set("variables", JSON.stringify(this.variables));
    url.searchParams.set("operationName", "NewsArticlesForZeldaPortal");

    const res = await fetch(url);
    const { data, errors } = await res.json();
    
    if (data && data.collection) return data.collection;
    
    throw new Error(
      `Failed to fetch articles due to: ${JSON.stringify(errors)}`
    );
  }
  createArticleElem({ title, slug, body, publishDate, media }) {
    const localizedDate = new Date(publishDate).toLocaleDateString(
      this.locale,
      dateFormat
    );
    const elem = document.createElement("li");
    const basePath =
      CONFIG.CONST.ENV.toLowerCase() === "local" ? "/local/" : "/";
    elem.classList.add("news-articles-wc__article");
    elem.innerHTML = `
			<a href="${basePath}news/#${slug}" class="news-articles-wc__link" target="_self">
				<div class="news-articles-wc__image-wrap">
					<img class="news-articles-wc__image" alt="" src="${media && media.url}">
				</div>
				<div class="news-articles-wc__content">
					<p class="news-articles-wc__date">${localizedDate}</p>
					<p class="news-articles-wc__title">${title}</p>
					<p class="news-articles-wc__intro">${body && body.text}</p>
					<div class="news-card__cta">Read more</div>
				</div>
			</a>
		`;
    return elem;

  }
}

ExecuteControllers.registerController(NewsArticles, "NewsArticles");

export default NewsArticles;
