import {default as ElementController, ExecuteControllers}  from 'wtc-controller-element';
import _u from 'wtc-utility-helpers';

class About extends ElementController {
  constructor (element) {
    super(element);

  }

}

ExecuteControllers.registerController(About, 'About');
export default About;
