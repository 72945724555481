import _u from 'wtc-utility-helpers';
import Breakpoints from 'wtc-utility-breakpoint';
import {default as ElementController, ExecuteControllers}  from 'wtc-controller-element';

class EqualHeight extends ElementController {
  constructor (element, options = {}) {
    super(element);
    let itemClass = element.getAttribute('data-item');
    this.items = element.querySelectorAll(itemClass);
    this.resizeTimer;

    window.addEventListener('resize',this.debounceResize.bind(this));
    this.resizeHandler();
  }

  debounceResize() {
    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(this.resizeHandler.bind(this),50);
  }
  resizeHandler() {
    if(Breakpoints.current > 2) {
      let maxHeight = 0;
      for(let i = 0; i < this.items.length; i++) {
        this.items[i].style.height = "auto";
        if(maxHeight < this.items[i].offsetHeight) {
          maxHeight = this.items[i].offsetHeight;
        }
      }
      for(let i = 0; i < this.items.length; i++) {
        this.items[i].style.height = `${maxHeight}px`;
      }
    } else {
      for(let i = 0; i < this.items.length; i++) {
        this.items[i].style.height = 'auto';
      }
    }
  }
}

ExecuteControllers.registerController(EqualHeight, 'EqualHeight');

export default EqualHeight;
