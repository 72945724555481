import _u from 'wtc-utility-helpers';
import {default as ElementController, ExecuteControllers}  from 'wtc-controller-element';
import TimelineParser from '../Timeline/TimelineParser';
import CONFIG from '../config';
import FontFaceObserver from 'fontfaceobserver';

// Timeline
import Timeline from '../Timeline/Timeline';

class TimelinePage extends ElementController {
  constructor (element) {
    super(element);

    // SMALL LABELS ACTIVE SWITCHER
    let labels = document.querySelectorAll('.split__option-title');
    let labelClick = function(e) {
      setTimeout(()=> {
        for (let label of labels) {
          let input = document.getElementById(label.getAttribute('for'));


          if (!input.checked) {
            _u.removeClass('is-active', label);
          }
        }

        _u.addClass('is-active', e.target);
      }, 10);
    };

    for (let item of labels) {
      item.addEventListener('click', labelClick, true);
    }

    // DESKTOP
    let loader = PIXI.loader;
    let images = { gradient: `${CONFIG.CONST.ASSETS}img/timeline/gradient.png` };
    let imagesloaded = false;
    let fontsloaded = false;

    let tryFinally = function() {
      if(imagesloaded && fontsloaded) {
        setTimeout(function() {
          Timeline.init();
          setUpScreens();
        }, 1000);
      }
    };

    for( let key in images ) {
      loader.add(key, images[key]);
    }

    loader.load((loader, resources)=> {
      imagesloaded = true;
      tryFinally();
    });

    let bitter = new FontFaceObserver('Bitter');
    let cinzel = new FontFaceObserver('Cinzel');

    Promise.all([bitter.load(), cinzel.load()]).then(function () {
      fontsloaded = true;
      tryFinally();
    });

    // DESKTOP SCREENS
    let desktopWrapper = document.querySelector('.wrapper--big');
    let screensMainWrapper = desktopWrapper.querySelector('.timeline-screens');
    let screens = [];
    let currentScreen = null;

    let setUpGameEvents = function(screen) {
      _u.forEachNode(screen.querySelectorAll('.timeline-screen--timeline__game, .timeline-screen__split-options button'), function(index, item) {
        item.addEventListener('click', function(e) {
          e.preventDefault();
          e.stopPropagation();
          let id = e.currentTarget.getAttribute('data-id');
          let col = Timeline.collection;
          let colEv = col.getEventCollection();
          let ev = null;

          for (let i = 0; i < colEv.length; i++) {
            if (colEv[i].id == id) {
              ev = col.findEventByIndex(colEv[i].timelineId, colEv[i].index);
            }
          }

          if (ev) {
            ev.displayObject.select(true);
          }
        }, false);
      });
    };

    let setUpSplitButtons = function(screen) {
      _u.forEachNode(screen.querySelectorAll('.timeline-screen__split-options button'), function(index, item) {
        item.addEventListener('click', function(e) {
          let id = e.currentTarget.getAttribute('data-id');
          let optionId = e.currentTarget.getAttribute('data-option');
          let optionNumber = e.currentTarget.getAttribute('data-number');
          let col = Timeline.collection;
          let colEv = col.getEventCollection();
          let ev = null;

          for (let i = 0; i < colEv.length; i++) {
            if (colEv[i].id == id) {
              ev = col.findEventByIndex(colEv[i].timelineId, colEv[i].index);
            }
          }

          if (ev) {
            for(let option of ev.options) {
              if (option.id === optionId) {
                ev.displayObject.gotoTimeline(ev.displayObject[optionNumber], option.goto);
              }
            }
          }
        }, false)
      });
    }

    let setUpScreens = function() {
      screens = screens.concat(Timeline.screens);

      for (let screen of screens) {
        screensMainWrapper.appendChild(screen);

        if(_u.hasClass('timeline-screen--timeline', screen)) {
          setUpGameEvents(screen);
        }
        else if (_u.hasClass('timeline-screen--split', screen)) {
          setUpSplitButtons(screen);
        }
      }

      currentScreen = screens[0];
      swapScreens(null, currentScreen);

      resizeScreensWrapper();
    };

    let changeDelayEvent = null;
    window.addEventListener('EventSelected', function(e) {
      clearTimeout(changeDelayEvent);

      (function(e) {
        changeDelayEvent = setTimeout(function() {
          let data = e.detail.event.data;
          let nextScreen = screensMainWrapper.querySelector(`.timeline-screen[data-id="${data.id}"]`);

          if(e.detail.direct) {
          	nclood.Metrics.trackLink({
          		eVars: { 39: (data.type == 'split') ? 'button: ' + data.title.toLowerCase() : data.title.toLowerCase() },
          		events: [ 53 ]
          	});
          }

          swapScreens(currentScreen, nextScreen);

          currentScreen = nextScreen;

          if (_u.hasClass('timeline-screen--split', currentScreen)) {
            let splitUl = currentScreen.querySelector('.timeline-screen__split-options');
            if (splitUl) splitUl.focus();
          }
        }, 300);
      })(e);

    });

    let changeDelay = null;
    window.addEventListener('TimelineSelected', function(e) {
      clearTimeout(changeDelay);

      (function(e) {
        changeDelay = setTimeout(function() {

          let id = e.detail.timelineName;
          let nextScreen = screensMainWrapper.querySelector(`[data-id="${id}"]`);

        	nclood.Metrics.trackLink({
        		eVars: { 39: 'button: ' + e.detail.title.toLowerCase() },
        		events: [ 53 ]
        	});

          if (nextScreen) {
            swapScreens(currentScreen, nextScreen);

            currentScreen = nextScreen;
          }
        }, 300);
      })(e);
    });

    let swapScreens = function(current, next) {
      let deactivate = function(element) {
        element.setAttribute('aria-hidden', 'true');
        element.setAttribute('tabindex', '-1');
      }

      let activate = function(element) {
        element.removeAttribute('aria-hidden');
        element.removeAttribute('tabindex');
      }

      if (current) {
        _u.removeClass('is-active', current);
        current.setAttribute('aria-hidden', 'true');

        for (let game of current.querySelectorAll('.timeline-screen--timeline__game')) {
          deactivate(game);
        }

        for (let button of current.querySelectorAll('.timeline-screen__split-options button')) {
          deactivate(button);
        }

        let siteLink = current.querySelector('.timeline-screen--game__link');
        if (siteLink) deactivate(siteLink);
      }

      if (next) {
        _u.addClass('is-active', next);
        next.removeAttribute('aria-hidden');

        for (let game of next.querySelectorAll('.timeline-screen--timeline__game')) {
          activate(game);
        }

        for (let button of next.querySelectorAll('.timeline-screen__split-options button')) {
          activate(button);
        }

        let siteLink = next.querySelector('.timeline-screen--game__link');
        if (siteLink) activate(siteLink);
      }
    }

    // RESIZE FOR SCREENS WRAPPER
    let resizeScreensWrapper = function () {
      let maxH = 0;
      for (let screen of screens) {
        let h = screen.offsetHeight;
        if (h > maxH) {
          maxH = h;
        }
      }
      screensMainWrapper.style.height = `${maxH}px`;
    };
    window.addEventListener('resize', resizeScreensWrapper);

  }
}

ExecuteControllers.registerController(TimelinePage, 'TimelinePage');

export default TimelinePage;
